import 'swiper/css'
/**
 * vendor
 */
import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Controller, Pagination } from 'swiper'
import Image from 'next/image'
/**
 * ui
 */
import StyledDefaultButton from '../Button/Default'
/**
 * images
 */
import Play from 'public/assets/icons/play-renew.svg'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
/**
 * utils
 */
import useClientSide from '../../hooks/useClientSide'
/**
 * static data
 */
const data: { url: string; thumbnail: string }[] = [
  {
    url: '201130935',
    thumbnail: 'fotografiya-s-nulya.png',
  },
  {
    url: '201130933',
    thumbnail: 'copyraiter-a-z.png',
  },
  {
    url: '201130931',
    thumbnail: 'yandex-direct-s-nulya.png',
  },
  {
    url: '201156919',
    thumbnail: 'copyraiter-a-z2.png',
  },
  {
    url: '201132416',
    thumbnail: 'yandex-direct-s-nulya2.png',
  },
]

const Comments: React.VFC = () => {
  const [swiper, setSwiper] = React.useState<SwiperCore>()
  const dispatch = useAppDispatch()
  const isClientSide = useClientSide()

  const setModal = (url: string) => {
    dispatch({
      type: 'SET_COMMENT_VIDEO',
      commentVideo: {
        url: url,
        isOpen: true,
      },
    })
  }
  if (!isClientSide) return null
  return (
    <StyledTestimonialsSection>
      <StyledSwiper
        modules={[Controller, Navigation, Pagination]}
        controller={{ control: swiper }}
        spaceBetween={20}
        slidesPerView={1}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        onSwiper={setSwiper}
        breakpoints={{
          575.98: {
            slidesPerView: 1.3,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          743.98: {
            slidesPerView: 2.3,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          1199.98: {
            slidesPerView: 3.3,
            spaceBetween: 15,
            slidesPerGroup: 1,
          },
        }}
      >
        {data.map((item, idx) => (
          <SwiperSlide key={idx.toString()}>
            <StyledCard>
              <StyledVideoThumbnail>
                <Image
                  src={`/assets/images/comments/${item.thumbnail}`}
                  alt='specialist'
                  layout='fill'
                  objectFit='cover'
                />
              </StyledVideoThumbnail>
              <StyledControlButtons>
                <StyledButtonVideo
                  aria-label='play'
                  onClick={() => {
                    setModal(item.url)
                  }}
                >
                  <StyledPlay />
                </StyledButtonVideo>
              </StyledControlButtons>
            </StyledCard>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </StyledTestimonialsSection>
  )
}

export default Comments

const StyledSection = styled.div`
  position: relative;
`

const StyledTestimonialsSection = styled(StyledSection)`
  position: relative;
  margin-top: 24px;
`

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    .swiper-pagination-bullet {
      display: none;
    }
  }
`

const StyledCard = styled.div`
  height: 558px;
  border: 1px solid ${({ theme }) => theme.colors.greySecondary};
  ${({ theme }) => theme.mixins.boxSizing()}
  border-radius: 26px;
  position: relative;
  margin-bottom: 33px;
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 26px;
  }
`

const StyledVideoThumbnail = styled.div`
  border-radius: 26px;
  overflow: hidden;
  opacity: 1;
  ${({ theme }) => theme.mixins.transition('opacity 800ms, height 0s')}
  ${({ theme }) => theme.mixins.transitionDelay('0s, 0s')}
  
  img {
    border-radius: 26px;
  }
`

const StyledControlButtons = styled.div`
  position: absolute;
  padding: 0 10px;
  top: 50%;
  left: 0;
  width: 100%;
  ${({ theme }) => theme.mixins.translateY('-50%')}
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 0 20px;
  }
`

const StyledButtonVideo = styled(StyledDefaultButton)`
  width: 121px;
  height: 121px;
  border-radius: 50%;
  background: #fff;
  &:hover {
    ${({ theme }) =>
      theme.mixins.boxShadow('0px 14px 24px rgba(24, 168, 102, 0.4)')}
  }
`

const StyledPlay = styled(props => <Play {...props} />)`
  width: 43px;
  height: 43px;
`

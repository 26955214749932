import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import getConfig from 'next/config'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
/**
 * ui
 */
import Icon from '../Icon'
import { TCurrency } from '../../api/product'

const {
  publicRuntimeConfig: { STATIC_URL },
} = getConfig()

interface Style {}

interface Props extends Style {
  title: string
  category: string
  countModule: number
  countLesson: number
  slug: string
  accessType: number
  subscription?: {
    status: number
    stopReason?: number
    accessType: number
  }
  initialAmounts: {
    [type: string]: number
  } | null
  isLoggedIn: boolean
  dayPrice: number | null
  imagePath?: string | null
  fullPrice: number
  categorySlug: string
  currency: string | null
}

const ModuleCardRenewal: React.FC<Props> = ({
  title,
  category,
  countModule,
  countLesson,
  slug,
  accessType,
  subscription,
  isLoggedIn,
  initialAmounts,
  dayPrice,
  imagePath = null,
  fullPrice,
  categorySlug,
  currency,
}) => {
  const { t } = useTranslation()
  // translation component
  const { t: tc } = useTranslation('components.moduleCard')
  const [convertInitialAmounts, setConvertInitialAmounts] = useState<{
    [type: string]: string
  } | null>(null)

  const getCurrency = (currency: string) => {
    switch (currency) {
      case 'RUB':
        return TCurrency.RUB_SYMBOL
        break
      case 'AMD':
        return TCurrency.AMD_SYMBOL
        break
      default:
        return TCurrency.RUB_SYMBOL
    }
  }

  useEffect(() => {
    if (initialAmounts) {
      const convertPrice: { [type: string]: string } = {}

      ;(Object.keys(initialAmounts) as Array<string>).forEach(type => {
        convertPrice[type] = t('intlNumber', {
          val: initialAmounts[type] / 100,
        })
      })
      setConvertInitialAmounts(convertPrice)
    }
  }, [initialAmounts])
  return (
    <Link href={`/${categorySlug}/${slug}`} passHref>
      <StyledModuleCardRenewal>
        {/* image */}
        <StyledModuleCardRenewalImage>
          <Image
            src={
              imagePath
                ? `${STATIC_URL}/${imagePath}`
                : '/assets/images/logo_black.svg'
            }
            layout='fill'
            alt='courseImage'
            objectFit='cover'
          />
        </StyledModuleCardRenewalImage>
        {/* category */}
        <StyledModuleCardRenewalCategory>
          {category}
        </StyledModuleCardRenewalCategory>
        {/* title */}
        <StyledModuleCardRenewalTitle>{title}</StyledModuleCardRenewalTitle>
        {/* module and lessons */}
        <StyledModuleCardRenewalRow>
          {/* module */}
          <StyledModuleCardInfo>
            <StyledModuleCardIcon>
              <Icon name='course-card' width='24' height='24' />
            </StyledModuleCardIcon>
            <StyledModuleCardText>
              {t('weeks', { count: countModule })}
            </StyledModuleCardText>
          </StyledModuleCardInfo>
          {/* lessons */}
          <StyledModuleCardInfo>
            <StyledModuleCardIcon>
              <Icon name='book' width='24' height='24' />
            </StyledModuleCardIcon>
            <StyledModuleCardText>
              {t('lessons', { count: countLesson })}
            </StyledModuleCardText>
          </StyledModuleCardInfo>
        </StyledModuleCardRenewalRow>
        {/* price and btn */}
        {/* если не авторизирован, используем accessType прямо */}
        {(!isLoggedIn || (isLoggedIn && !subscription)) &&
          accessType === 1 &&
          convertInitialAmounts && (
            <StyledModuleCardPrice>
              <StyledModuleCardPriceInner>
                <StyledModuleCardPriceHighlight>
                  {dayPrice &&
                    currency &&
                    tc('price', {
                      val: (dayPrice * 28) / 100,
                      currency: getCurrency(currency),
                    })}
                </StyledModuleCardPriceHighlight>
                <StyledModuleCardPriceInnerText>
                  {tc('perMonth')}
                </StyledModuleCardPriceInnerText>
              </StyledModuleCardPriceInner>
              <StyledVerticalHr />
              <StyledModuleCardPriceInner>
                <StyledModuleCardPriceHighlight>
                  {currency &&
                    tc('price', {
                      val: fullPrice / 100,
                      currency: getCurrency(currency),
                    })}
                </StyledModuleCardPriceHighlight>
                <StyledModuleCardPriceInnerText>
                  {tc('fullCourse')}
                </StyledModuleCardPriceInnerText>
              </StyledModuleCardPriceInner>
            </StyledModuleCardPrice>
          )}
        {(!isLoggedIn || (isLoggedIn && !subscription)) && accessType === 2 && (
          <StyledModuleCardPrice>
            <StyledModuleCardPriceHighlight>
              {tc('free')}
            </StyledModuleCardPriceHighlight>
          </StyledModuleCardPrice>
        )}
        {(!isLoggedIn || (isLoggedIn && !subscription)) && accessType === 3 && (
          <StyledModuleCardPricePremium>
            <StyledModuleCardPriceHighlight>
              {tc('free')}
            </StyledModuleCardPriceHighlight>
            <StyledModuleCardPriceInnerText>
              {tc('forPremium')}
            </StyledModuleCardPriceInnerText>
          </StyledModuleCardPricePremium>
        )}
        <StyledButton>{tc('more')}</StyledButton>
      </StyledModuleCardRenewal>
    </Link>
  )
}

export default React.memo(ModuleCardRenewal)

const StyledModuleCardRenewalCategory = styled.div`
  ${({ theme }) => theme.mixins.H5};
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 16px;
`

const StyledModuleCardRenewalTitle = styled.div`
  ${({ theme }) => theme.mixins.H5};
  margin-bottom: 24px;
  height: 60px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H4};
  }
`

const StyledModuleCardIcon = styled.div`
  margin-right: 15px;
`

const StyledModuleCardPriceHighlight = styled.span`
  font-weight: 600;
  font-size: 1.563rem;
  line-height: 30.9px;
  color: ${({ theme }) => theme.colors.primary};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
  }
`

const StyledModuleCardPriceInnerText = styled.span`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
  }
`

const StyledModuleCardText = styled.p`
  ${({ theme }) => theme.mixins.textNormal};
`

const StyledButton = styled.button`
  width: 100%;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.text};
  color: ${({ theme }) => theme.colors.primary};
  background: #fff;
  padding: 10px 0;
  border-radius: 50px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  border: 1px solid ${({ theme }) => theme.colors.primary};

  path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`

const StyledModuleCardRenewal = styled.a`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 20px;
  padding: 16px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  box-shadow: -3px 2px 23px rgba(0, 0, 0, 0.05);
  &:visited {
    text-decoration: none;
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }

  &:hover ${StyledModuleCardRenewalCategory} {
    color: #fff;
  }

  &:hover ${StyledModuleCardRenewalTitle} {
    color: #fff;
  }

  &:hover ${StyledModuleCardIcon} {
    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &:hover ${StyledModuleCardPriceHighlight} {
    color: #fff;
  }

  &:hover ${StyledModuleCardPriceInnerText} {
    color: ${({ theme }) => theme.colors.grey100};
  }

  &:hover ${StyledModuleCardText} {
    color: ${({ theme }) => theme.colors.grey100};
  }

  &:hover ${StyledButton} {
    border: 1px solid #fff;
  }
`

const StyledModuleCardRenewalImage = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.grey100};
  border-radius: 20px;
  min-height: 240px;
  overflow: hidden;
`

const StyledModuleCardRenewalRow = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
`

const StyledModuleCardInfo = styled.div`
  ${({ theme }) => theme.mixins.inlineFlex()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  
  & + & {
    margin-left: 14px;
  }
`

const StyledModuleCardPrice = styled.div`
  height: 47px;
  ${({ theme }) => theme.mixins.inlineFlex()}
  margin-top: 24px;
  margin-bottom: 24px;
`

const StyledModuleCardPriceInner = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
`

const StyledVerticalHr = styled.div`
  height: 45px;
  margin-left: 14px;
  margin-right: 14px;
  border-left: 2px solid ${({ theme }) => theme.colors.greySecondary};
`

const StyledModuleCardPricePremium = styled(StyledModuleCardPrice)`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
`

/**
 * vendor
 */
import React, {
  useEffect,
  useState,
  useCallback,
  LegacyRef,
  useRef,
} from 'react'
import type {
  GetServerSideProps,
  NextPage,
  GetServerSidePropsContext,
} from 'next'
import Head from 'next/head'
import styled, { css } from 'styled-components'
import { dehydrate, QueryClient } from 'react-query'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Controller,
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import getConfig from 'next/config'
import Image from 'next/image'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation, Trans } from 'next-i18next'
import { motion, useInView } from 'framer-motion'
import Link from 'next/link'
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse'
/**
 * context
 */
import { useAppDispatch, useAppState } from 'src/context/app'
/**
 * api
 */
import { productApi } from 'src/api'
import { useProductQuery } from 'src/api/product'
import { useCategoryQuery } from 'src/api/category'
import { useSecureQuery } from 'src/api/secure'
import mainCMSApi, { queryMainCMS, IMainCMS } from 'src/api/main-page'
/**
 * ui
 */
import Layout, { MainStyle } from 'src/components/Layout/Layout'
import Comments from 'src/components/Comments'
import ModuleCard from 'src/components/ModuleCard'
import { FooterStyle } from 'src/components/Footer'
import { TModal } from 'src/components/Modal'
import { notify, ToastIcon } from 'src/components/Toast'
import Hide from 'src/components/Hide'
import Icon from 'src/components/Icon'
import ButtonDefault from 'src/components/Button/Default'
import ROUNDEDBTN from '/public/assets/images/rounded-slider-btn.svg'
/**
 * hook
 */
import useHeaders from 'src/hooks/useHeaders'
import useClientSide from 'src/hooks/useClientSide'
import useWindowSize from 'src/hooks/useWindowSize'
/**
 * constants
 */
import {
  ALERT_CLOSE_WAIT,
  PERSONAL_DATA,
  OFERTA,
  PRIVACY_POLICY,
  LICENSE,
} from 'src/constants'

// VideoPlayer
import dynamic from 'next/dynamic'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

const Player = dynamic<
  ReactPlayerProps & {
    playerref: LegacyRef<ReactPlayer>
  }
>(import('src/components/Player/YouTubePlayer'), {
  ssr: false,
})

const {
  publicRuntimeConfig: { API_URL, INTERNALIZATION, STATIC_ASSETS_COMMON_URL },
} = getConfig()

// const cooperateIcons: {
//   url: string
//   alt: string
// }[] = [
//   {
//     url: '/assets/images/tinkoff.png',
//     alt: 'тинькофф',
//   },
//   { url: '/assets/images/vk.png', alt: 'вконтакте' },
//   { url: '/assets/images/sber.png', alt: 'сбер' },
//   { url: '/assets/images/forbes.png', alt: 'Форбс' },
//   { url: '/assets/images/mail-ru.png', alt: 'mail.ru' },
//   { url: '/assets/images/tutortop.png', alt: 'tutortop' },
//   { url: '/assets/images/kwork.png', alt: 'kwork' },
//   { url: '/assets/images/cloud.png', alt: 'cloud payments' },
// ]

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
}

const reviews: {
  profile: string
  name: string
  course: string
  start: number
  review: string
  from?: string
  link?: string
}[] = [
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-1.png`,
    name: 'Максим Остапчук',
    course: 'Киберспортсмен',
    start: 5,
    review: `Очень актуально, профессионально и подробно. Мне ну очень понравился курс. Раньше дико горел от поражений, но теперь абсолютно на расслабоне. \nСамый крутой плюс этого курса - эксперты, которые реально шарят в киберспорте. Впервые за столько лет посмотрел на игры совсем под другим углом и вывел свой скилл совершенно на новый уровень. Авторам курса - большой рекспект!`,
    from: 'ВК отзыв',
    link: 'https://vk.com/id452788972',
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-2.png`,
    name: 'Дарья Романовски',
    course: 'Копирайтер от А до Я',
    start: 5,
    review: `Получила от курса все, что хотела. Я давно пыталась влиться в новую профессию, но мне всегда не хватало каких-то навыков или знаний. Цены на образование сейчас аховые. И спасибо willskill — я спокойно отучилась, не ждала, пока накоплю.\nКурс помог разобраться, как работать с текстом абсолютно на всех этапах. Очень быстро стала набирать заказы и вижу, что сильно выросла, как специалист. `,
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-3.png`,
    name: 'Евгения Шорохова',
    course: 'Английский язык с нуля',
    start: 5,
    review: `Вновь приступила к изучению английского в 24. Сколько учила его в школе, университете, и все без толку. Решила попробовать снова, и получила огромное удовольствие от прохождения курса. Занимаюсь где хочу и когда хочу (правда, хочу я, как правило, просто лежа на диване). И самый большой кайф - я наконец-то действительно начинаю ПОНИМАТЬ эту бесконечную грамматику английского, чем не могла похвастаться за все свои попытки изучения языка. I'm happy :) `,
    from: 'ВК отзыв',
    link: 'https://vk.com/id426806999',
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-4.png`,
    name: 'Александра Михеева',
    course: 'Фотография с нуля',
    start: 5,
    review: `Программа супер! Воплотила в жизнь свою давнюю мечту. Теперь не мама в декрете, а профессиональный фотограф)) Занималась, когда появлялась свободная минутка. Прямо с телефона. Никто не подгонял с выполнением домашних заданий и практик, что для меня огромный плюс! `,
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-5.png`,
    name: 'Юлия Николаева',
    course: 'NFT для цифровых артистов',
    start: 5,
    review: `Роман и Назар действительно профессионалы своего дела! Все по полочкам, без воды. Еще во время обучения я продала свои первые NFT работы, заработав на этом в 3 раза больше, чем зарабатывала, работая баристой. Считаю этот курс одной из моих лучших инвестиций!`,
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-6.png`,
    name: 'Анастасия Гардер',
    course: 'Таргетолог с нуля',
    start: 5,
    review: `Курс мне очень помог! Пошла на него, чтобы лучше понимать таргет, повысить навык как маркетолог. Думала, прослушаю для общего развития на фоне. Но нашла там годные практические советы. Однозначно советую вилскилл!`,
    from: 'ВК отзыв',
    link: 'https://vk.com/id437110166',
  },
  {
    profile: `${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-profile-7.png`,
    name: 'Станислава Бойко',
    course: 'Бизнес на сильной франшизе',
    start: 5,
    review: `До курса я и подумать не могла, что у меня есть предпринимательская жилка. Купила его, потому что стало интересно, решила подсобрать информацию. А в итоге работаю со своей косметической точкой, хорошо получаю и с ужасом думаю, что всего этого могло бы и не быть! Спасибо вилскил за новую жизнь!!`,
  },
]

const logoString = 'WILLSKILL · WILLSKILL · WILLSKILL ·'
const Home: NextPage<{ data: IMainCMS; previousRoute?: string }> = ({
  data: contentsData,
  previousRoute,
}) => {
  const { meta } = contentsData
  const { t } = useTranslation('pages.index')
  const dispatch = useAppDispatch()
  const { code } = useAppState()
  const { query } = useRouter()
  const { isLoggedIn } = useHeaders()
  const isClientSide = useClientSide()
  const [swiperCatalogue, setSwiperCatalogue] = React.useState<SwiperCore>()
  const [categorySlug, setCategorySlug] = useState<string>('')
  const [isLoopAllow, setIsLoopAllow] = React.useState(true)
  const { width } = useWindowSize()
  // block 11
  const [swiperReviews, setSwiperReviews] = React.useState<SwiperCore>()
  const [reviewsActiveIndex, setReviewsActiveIndex] = React.useState(0)
  // Block 7
  const [isOn1, setIsOn1] = useState(false)
  const [isOn2, setIsOn2] = useState(false)
  const [isOn3, setIsOn3] = useState(false)
  const [isOn4, setIsOn4] = useState(false)
  const switchRef = useRef<HTMLUListElement>(null)
  const switchBlockIsInView = useInView(switchRef, { once: true })
  // VideoPlayer
  const [videoId, setVideoId] = useState<string>('')
  const playerref = React.useRef<any>(null)
  const onReady = useCallback(() => {
    if (playerref.current) {
      playerref.current!.player.player.player.playVideo()
    }
  }, [playerref])
  // data fetching
  const { data: categoriesData } = useCategoryQuery.useGetCategories()
  const { data: productsData } = useProductQuery.useGetProducts({
    categorySlug,
  })

  // block 6
  const refMessageBlock = useRef<HTMLDivElement>(null)
  const isInViewMessageBlock = useInView(refMessageBlock, { once: true })
  //block 11
  // const refCounter = useRef<HTMLDivElement>(null)
  // const isInViewCounter = useInView(refCounter, { once: true })
  // block 12
  const refSvgLine = useRef<HTMLDivElement>(null)
  const isInViewSvgLine = useInView(refSvgLine, { once: true })
  // block 13
  const articleRef = useRef<HTMLDivElement>(null)
  const isInViewArticleBlock = useInView(articleRef, { once: true })
  const logoRef = useRef<HTMLDivElement>(null)
  const isInViewLogoBlock = useInView(logoRef, { once: true })
  // block 19
  const [isSpread, setIsSpread] = useState<boolean | null>(null)
  const brandsRef = useRef<HTMLDivElement>(null)
  const isInViewBrandsBlock = useInView(brandsRef, { once: true })

  React.useEffect(() => {
    if (productsData && productsData.items.length < 3) {
      setIsLoopAllow(() => false)
    }
  }, [productsData])

  useEffect(() => {
    if (query.category) {
      setCategorySlug(query.category as string)
    }
    if (query.code) {
      dispatch({
        type: 'SET_RESET_PASSWORD_CODE',
        code: query.code as string,
      })
    }
    // from landing page
    if (query.modal && !isLoggedIn) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: true,
        modalType: TModal.LOGIN,
      })
    }
    // from landing page
    if (query.modal && isLoggedIn) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      })
      // have to remove query ?modal=login?
    }
  }, [query, dispatch, isLoggedIn])

  const { data: checkCodeData } = useSecureQuery.useGetResetPasswordCheck(code)

  // new password code check
  useEffect(() => {
    if (checkCodeData && checkCodeData.data.success && code) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: true,
        modalType: TModal.NEW,
      })
    }
    if (checkCodeData && !checkCodeData.data.success && code) {
      notify({
        icon: ToastIcon.ERROR,
        autoClose: ALERT_CLOSE_WAIT,
        label:
          'Ссылка для смены пароля уже не активна. Запросите восстановление пароля повторно.',
      })
    }
  }, [dispatch, code, checkCodeData])
  // set previous router когда попал не авторизаванно
  useEffect(() => {
    if (previousRoute) {
      dispatch({
        type: 'SET_PREVIOUS_ROUTE',
        previousRoute,
      })
    }
  }, [previousRoute, dispatch])

  // block 7 for auto switching
  useEffect(() => {
    const timer1 =
      switchBlockIsInView &&
      setTimeout(() => {
        setIsOn1(!isOn1)
      }, 500)

    const timer2 =
      switchBlockIsInView &&
      setTimeout(() => {
        setIsOn2(!isOn2)
      }, 1000)
    const timer3 =
      switchBlockIsInView &&
      setTimeout(() => {
        setIsOn3(!isOn3)
      }, 1500)
    const timer4 =
      switchBlockIsInView &&
      setTimeout(() => {
        setIsOn4(!isOn4)
      }, 2000)
    return () => {
      timer1 && clearTimeout(timer1)
      timer2 && clearTimeout(timer2)
      timer3 && clearTimeout(timer3)
      timer4 && clearTimeout(timer4)
    }
  }, [switchBlockIsInView])

  // block 19 brans block ref is view
  useEffect(() => {
    if (isInViewBrandsBlock) {
      setIsSpread(prev => {
        if (prev === null) return true
        return !prev
      })
    }
  }, [isInViewBrandsBlock])

  const toggleSwitch = useCallback(
    (index: number) => {
      switch (index) {
        case 1:
          setIsOn1(!isOn1)
          break
        case 2:
          setIsOn2(!isOn2)
          break
        case 3:
          setIsOn3(!isOn3)
          break
        case 4:
          setIsOn4(!isOn4)
          break
        default:
          break
      }
    },
    [isOn1, isOn2, isOn3, isOn4],
  )
  return (
    <Layout mainStyle={MainStyle.MAINPTS} footerStyle={FooterStyle.BLOCK}>
      <Head>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:image' content={meta.image} />
        <meta property='og:url' content={`${API_URL}`} />
        <link rel='canonical' href={`${API_URL}`} />
      </Head>
      {/* new row-1 */}
      <StyledHeroContainer>
        <StyledBackground>
          <Image
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-1-bg.png`}
            layout='fill'
            alt='background'
            objectFit='cover'
            priority
          />
        </StyledBackground>

        <StyledBackgroundMobile>
          <Image
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-1-bg-mobile.png`}
            layout='fill'
            alt='background'
            objectFit='cover'
            priority
          />
        </StyledBackgroundMobile>
        <Container>
          <StyledRowFirst>
            {/* information */}
            <StyledRowFirstInformationWrapper>
              {/* subtitle */}
              <StyledRowFirstSubtitle>
                <Trans
                  t={t}
                  i18nKey='row-1.subtitle'
                  components={{
                    '1': <StyledRowFirstSubtitleTag />,
                  }}
                />
              </StyledRowFirstSubtitle>
              {/* title */}
              <StyledRowFirstTitleWrapper>
                <StyledRowFirstTitleBoldTop>
                  <span>{t('row-1.title-1')}</span>
                </StyledRowFirstTitleBoldTop>

                <StyledRowFirstTitleNormal>
                  {t('row-1.title-2')}
                </StyledRowFirstTitleNormal>

                <StyledRowFirstTitleBoldBottom>
                  <span>{t('row-1.title-3')}</span>
                </StyledRowFirstTitleBoldBottom>
              </StyledRowFirstTitleWrapper>

              <StyledRowFirstButtonWrapperDesktop>
                <StyledRowFirstButtonContainer>
                  <Link href={`/#catalogue`} passHref>
                    <StyledLink>{t('row-1.button')}</StyledLink>
                  </Link>
                </StyledRowFirstButtonContainer>
              </StyledRowFirstButtonWrapperDesktop>
            </StyledRowFirstInformationWrapper>
            {/* image */}
            <StyledRowFirstImage>
              <StyledRowFirstVideo
                autoPlay
                loop
                muted
                playsInline
                controls={false}
              >
                <source
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-1-logo.webm`}
                  type='video/webm'
                />
                <source
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-1-logo.mp4`}
                  type='video/mp4'
                />
                <track />
              </StyledRowFirstVideo>

              <Image
                alt='willskill'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-1-lamp.png`}
                width={519}
                height={612}
                priority
              />
            </StyledRowFirstImage>

            <StyledRowFirstButtonWrapper>
              <Link href='/#catalogue' passHref>
                <StyledLink>{t('row-1.button')}</StyledLink>
              </Link>
            </StyledRowFirstButtonWrapper>
          </StyledRowFirst>
        </Container>
      </StyledHeroContainer>

      {/* new row-2 */}
      <StyledWordContainer globalFactorX={0.1} globalFactorY={0.1}>
        <StyledWordLogo1 factorX={0.3} factorY={0.5} inverted={false}>
          <Image
            alt='logo'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-2-logo-1.png`}
            width={162}
            height={162}
          />
        </StyledWordLogo1>

        <StyledWordLogo2 factorX={0.3} factorY={0.5} inverted={false}>
          <Image
            alt='logo'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-2-logo-2.png`}
            width={162}
            height={162}
          />
        </StyledWordLogo2>

        <StyledWordLogo3 factorX={0.3} factorY={0.5} inverted={false}>
          <Image
            alt='logo'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-2-logo-1.png`}
            width={162}
            height={162}
          />
        </StyledWordLogo3>
        <StyledWordLogo4 factorX={0.3} factorY={0.5} inverted={false}>
          <Image
            alt='logo'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-2-logo-5.png`}
            width={162}
            height={162}
          />
        </StyledWordLogo4>

        <StyledWordLogo5 factorX={0.3} factorY={0.5} inverted={false}>
          <Image
            alt='logo'
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-2-logo-1.png`}
            width={162}
            height={162}
          />
        </StyledWordLogo5>
        <StyledWordRow>
          <StyledWordRowBlock>
            <StyledWordRowBlockText>{t('row-2.title')}</StyledWordRowBlockText>
          </StyledWordRowBlock>

          <StyledWordRowBlock>
            <StyledWordRowBlockText>{t('row-2.title')}</StyledWordRowBlockText>
          </StyledWordRowBlock>

          <StyledWordRowBlock>
            <StyledWordRowBlockText>{t('row-2.title')}</StyledWordRowBlockText>
          </StyledWordRowBlock>

          <StyledWordRowBlock>
            <StyledWordRowBlockText>{t('row-2.title')}</StyledWordRowBlockText>
          </StyledWordRowBlock>
        </StyledWordRow>
      </StyledWordContainer>

      {/* new row-3 */}
      <StyledSelectContainer>
        <Image
          src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-bg.png`}
          layout='fill'
          alt='background'
          objectFit='cover'
        />
        <Container>
          <StyledSelect>
            <StyledSelectFirstColumn>
              {t('row-3.title')}

              <StyledSelectSecondColumnAccent>
                WillSkill
                <StyledSelectSecondColumnArrowMobile>
                  <Image
                    alt='arrow'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-arrow-2.svg`}
                    width={140}
                    height={33}
                  />
                </StyledSelectSecondColumnArrowMobile>
                <StyledSelectSecondColumnArrow>
                  <Image
                    alt='arrow'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-arrow.svg`}
                    width={297}
                    height={118}
                  />
                </StyledSelectSecondColumnArrow>
              </StyledSelectSecondColumnAccent>
            </StyledSelectFirstColumn>

            <StyledSelectSecondColumn>
              {/* item 1 */}
              <StyledSelectItemContainer>
                <StyledSelectItemImage>
                  <Image
                    alt='ease'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-ease.svg`}
                    width={98}
                    height={98}
                    unoptimized
                  />
                </StyledSelectItemImage>

                <StyledSelectItemTitle>
                  {t('row-3.subtitle-1')}
                </StyledSelectItemTitle>
                <StyledSelectItemDescription>
                  {t('row-3.description-1')}
                </StyledSelectItemDescription>
              </StyledSelectItemContainer>
              {/* item 2 */}
              <StyledSelectItemContainer>
                <StyledSelectItemImageSecond>
                  <Image
                    alt='ease'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-work-hard.svg`}
                    width={137}
                    height={55}
                  />
                </StyledSelectItemImageSecond>

                <StyledSelectItemTitle>
                  {t('row-3.subtitle-2')}
                </StyledSelectItemTitle>
                <StyledSelectItemDescription>
                  {t('row-3.description-2')}
                </StyledSelectItemDescription>
              </StyledSelectItemContainer>
              {/* item 3 */}
              <StyledSelectItemContainer>
                <StyledSelectItemImage>
                  <Image
                    alt='ease'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-3-idea.svg`}
                    width={81}
                    height={81}
                  />
                </StyledSelectItemImage>

                <StyledSelectItemTitle>
                  {t('row-3.subtitle-3')}
                </StyledSelectItemTitle>
                <StyledSelectItemDescription>
                  {t('row-3.description-3')}
                </StyledSelectItemDescription>
              </StyledSelectItemContainer>
            </StyledSelectSecondColumn>
          </StyledSelect>
        </Container>
      </StyledSelectContainer>

      {/* new Row 4 */}
      <StyledSectionVideoBlock>
        <Container>
          <StyledVideoContainer>
            {!videoId ? (
              <StyledVideoThumbnail>
                <StyledVideoThumbnailTitle>
                  {t('row-4.title')}
                </StyledVideoThumbnailTitle>
                <Image
                  alt='thumbnail'
                  width={1224}
                  height={width > 576 ? 574 : 704.8}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-4-video-block-cover.png`}
                />
                <StyledVideoOverlay onClick={() => setVideoId('3O6w9yub1I0')}>
                  <StyledButtonVideo aria-label='play'>
                    <StyledTriangle />
                  </StyledButtonVideo>
                </StyledVideoOverlay>
              </StyledVideoThumbnail>
            ) : (
              <StyledVideoPlayerWrapper>
                <Player
                  playerref={playerref}
                  url={`https://www.youtube.com/embed/${videoId}`}
                  className='ytplayer yt__main-player'
                  width='100%'
                  height='100%'
                  config={{
                    youtube: {
                      playerVars: {
                        controls: 1,
                        playsinline: 1,
                        modestbranding: 1,
                        rel: 0,
                        showsearch: 0,
                        fs: 0,
                        disablekb: 1,
                        enablejsapi: 1,
                        iv_load_policy: 1,
                        showinfo: 0,
                        mute: 0,
                        autoplay: 0,
                      },
                    },
                  }}
                  onReady={onReady}
                />
              </StyledVideoPlayerWrapper>
            )}
          </StyledVideoContainer>
        </Container>
      </StyledSectionVideoBlock>

      {/* new row - 5 */}
      <Container as={'section'}>
        <StyledReason internalization={JSON.parse(INTERNALIZATION) as boolean}>
          <StyledReasonItem data-value='01'>
            <StyledReasonItemImage>
              <Image
                alt='decent'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-5-decent.svg`}
                width={83}
                height={83}
              />
            </StyledReasonItemImage>

            <StyledReasonItemText>
              <Trans
                t={t}
                i18nKey={'row-5.item-1'}
                components={{
                  '1': <StyledAccent />,
                }}
              />
            </StyledReasonItemText>
          </StyledReasonItem>
          <StyledReasonItem data-value='02'>
            <StyledReasonItemImage>
              <Image
                alt='wallet'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-5-wallet.svg`}
                width={83}
                height={83}
              />
            </StyledReasonItemImage>

            <StyledReasonItemText>
              <Trans
                t={t}
                i18nKey={'row-5.item-2'}
                components={{ '1': <StyledAccent /> }}
              />
            </StyledReasonItemText>
          </StyledReasonItem>
          <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
            <StyledReasonItem data-value='03'>
              <StyledReasonItemImage>
                <Image
                  alt='money'
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-5-exchange.svg`}
                  width={83}
                  height={83}
                />
              </StyledReasonItemImage>

              <StyledReasonItemText>
                <Trans
                  t={t}
                  i18nKey={'row-5.item-3'}
                  components={{ '1': <StyledAccent /> }}
                />
              </StyledReasonItemText>
            </StyledReasonItem>
          </Hide>
        </StyledReason>
      </Container>

      {/* new Row - 6 */}
      <Container as={'section'}>
        <StyledBenefitRowWrapper>
          <StyledBenefitRowLeft>
            <StyledBenefitRowTitle>
              <Trans
                t={t}
                i18nKey={'row-6.title'}
                components={{
                  '1': <StyledBenefitRowCaption />,
                  '2': <StyledBenefitRowDecorationLine />,
                  '3': (
                    <Image
                      alt='decoration'
                      width={294}
                      height={24}
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-6-decoration-line.svg`}
                    />
                  ),
                }}
              />
            </StyledBenefitRowTitle>

            <StyledBenefitRowText>
              {t('row-6.description')}
            </StyledBenefitRowText>

            <StyledBenefitRowPriceBlockTest>
              <StyledBenefitPriceBlockWrapper>
                {t('row-6.course')}
              </StyledBenefitPriceBlockWrapper>
              <StyledBenefitRowPriceTest>
                <StyledBenefitRowPriceTestSpan>
                  {t('row-6.price')}
                </StyledBenefitRowPriceTestSpan>
              </StyledBenefitRowPriceTest>
            </StyledBenefitRowPriceBlockTest>

            <StyledBenefitFootnote>{t('row-6.comments')}</StyledBenefitFootnote>
          </StyledBenefitRowLeft>

          <StyledBenefitRowRight ref={refMessageBlock}>
            <StyledBenefitMessageBlock
              isView={isInViewMessageBlock}
              delay={0.9}
            >
              <StyledBenefitMessageAvatar>
                <Image
                  alt='avatar'
                  width={90}
                  height={90}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-6-avatar-1.svg`}
                />
              </StyledBenefitMessageAvatar>
              <StyledBenefitMessageTextBlue>
                {t('row-6.speech-1')}
              </StyledBenefitMessageTextBlue>
            </StyledBenefitMessageBlock>

            <StyledBenefitMessageBlock
              isView={isInViewMessageBlock}
              delay={1.8}
            >
              <StyledBenefitMessageTextGray>
                {t('row-6.speech-2')}
              </StyledBenefitMessageTextGray>
              <StyledBenefitMessageAvatar>
                <Image
                  alt='avatar'
                  width={90}
                  height={90}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-6-avatar-2.svg`}
                />
              </StyledBenefitMessageAvatar>
            </StyledBenefitMessageBlock>

            <StyledBenefitMessageBlock
              isView={isInViewMessageBlock}
              delay={2.7}
            >
              <StyledBenefitMessageAvatar>
                <Image
                  alt='avatar'
                  width={90}
                  height={90}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-6-avatar-1.svg`}
                />
              </StyledBenefitMessageAvatar>
              <StyledBenefitMessageTextBlue>
                {t('row-6.speech-3')}
              </StyledBenefitMessageTextBlue>
            </StyledBenefitMessageBlock>

            <StyledBenefitMessageBlock
              isView={isInViewMessageBlock}
              delay={3.6}
            >
              <StyledBenefitMessageTextGray>
                {t('row-6.speech-4')}
              </StyledBenefitMessageTextGray>
              <StyledBenefitMessageAvatar>
                <Image
                  alt='avatar'
                  width={90}
                  height={90}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-6-avatar-2.svg`}
                />
              </StyledBenefitMessageAvatar>
            </StyledBenefitMessageBlock>
          </StyledBenefitRowRight>
        </StyledBenefitRowWrapper>
      </Container>

      {/* new row - 7 */}
      <StyledHowWork>
        <StyledHowWorkLeftSide>
          <StyledTitle>
            <Trans
              t={t}
              i18nKey={'row-7.title'}
              components={{
                '1': <StyledAccent as={'span'} />,
              }}
            />
          </StyledTitle>

          <StyledHowWorkList ref={switchRef}>
            <StyledHowWorkListItem>
              <StyledSwitch data-ison={isOn1} onClick={() => toggleSwitch(1)}>
                <StyledSwitchHandle layout transition={spring} />
              </StyledSwitch>

              <StyledHowWorkListItemDescription data-ison={isOn1}>
                <Trans
                  t={t}
                  i18nKey={'row-7.item-1'}
                  components={{
                    '1': <StyledHowWorkListItemDescriptionStrong />,
                  }}
                />
              </StyledHowWorkListItemDescription>
            </StyledHowWorkListItem>

            <StyledHowWorkListItem>
              <StyledSwitch data-ison={isOn2} onClick={() => toggleSwitch(2)}>
                <StyledSwitchHandle layout transition={spring} />
              </StyledSwitch>

              <StyledHowWorkListItemDescription data-ison={isOn2}>
                <Trans
                  t={t}
                  i18nKey={'row-7.item-2'}
                  components={{
                    '1': <StyledHowWorkListItemDescriptionStrong />,
                  }}
                />
              </StyledHowWorkListItemDescription>
            </StyledHowWorkListItem>

            <StyledHowWorkListItem>
              <StyledSwitch data-ison={isOn3} onClick={() => toggleSwitch(3)}>
                <StyledSwitchHandle layout transition={spring} />
              </StyledSwitch>

              <StyledHowWorkListItemDescription data-ison={isOn3}>
                <Trans
                  t={t}
                  i18nKey={'row-7.item-3'}
                  components={{
                    '1': <StyledHowWorkListItemDescriptionStrong />,
                  }}
                />
              </StyledHowWorkListItemDescription>
            </StyledHowWorkListItem>

            <StyledHowWorkListItem>
              <StyledSwitch data-ison={isOn4} onClick={() => toggleSwitch(4)}>
                <StyledSwitchHandle layout transition={spring} />
              </StyledSwitch>

              <StyledHowWorkListItemDescription data-ison={isOn4}>
                <Trans
                  t={t}
                  i18nKey={'row-7.item-4'}
                  components={{
                    '1': <StyledHowWorkListItemDescriptionStrong />,
                  }}
                />
              </StyledHowWorkListItemDescription>
            </StyledHowWorkListItem>
          </StyledHowWorkList>
        </StyledHowWorkLeftSide>

        <StyledHowWorkVideoWrapper autoPlay loop muted playsInline>
          <source
            src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-7-laptop.webm`}
            type='video/webm'
          />
          <source
            src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-7-laptop.mp4`}
            type='video/mp4'
          />
          <track />
        </StyledHowWorkVideoWrapper>

        <StyledHowWorkVideoWrapperMob>
          <StyledHowWorkVideoMobile autoPlay loop muted playsInline>
            <source
              src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-7-mobile-new.webm`}
              type='video/webm'
            />
            <source
              src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-7-mobile-new.mp4`}
              type='video/mp4'
            />
            <track />
          </StyledHowWorkVideoMobile>
        </StyledHowWorkVideoWrapperMob>

        <StyledHowWorkSign>
          <Image
            alt='sign'
            width={468}
            height={70}
            src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-7-sign.png`}
          />
        </StyledHowWorkSign>
      </StyledHowWork>

      {/* new row - 8 */}
      <Container as={'section'}>
        <StyledCatalogue id='catalogue'>
          {/* sub title */}
          <StyledDescription>{t('row-8.description')}</StyledDescription>
          {/* title */}
          <StyledTitle>
            <Trans
              t={t}
              i18nKey='row-8.title'
              components={{ '1': <StyledAccent as={'span'} /> }}
            />
            <StyledCatalogueImage>
              <Image
                alt='bachelorCap'
                width={203}
                height={208}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-8-cap.png`}
              />
            </StyledCatalogueImage>
          </StyledTitle>
          {/* catalogue button */}
          <StyledCatalogueControl>
            <Link
              href={`/`}
              passHref
              scroll={false}
              shallow
              replace
              legacyBehavior
            >
              <StyledCatalogueControlBtn
                onClick={() => setCategorySlug(() => '')}
                clicked={'' === categorySlug}
              >
                {t('row-8.all')}
              </StyledCatalogueControlBtn>
            </Link>

            {categoriesData &&
              categoriesData.map(slug => (
                <Link
                  passHref
                  href={`/?category=${slug.slug}`}
                  key={slug.slug}
                  scroll={false}
                >
                  <StyledCatalogueControlBtn
                    clicked={slug.slug === categorySlug}
                  >
                    {slug.title}
                  </StyledCatalogueControlBtn>
                </Link>
              ))}
          </StyledCatalogueControl>

          <StyledCatalogueSwiper
            modules={[Controller, Navigation, Pagination]}
            controller={{ control: swiperCatalogue }}
            onSwiper={setSwiperCatalogue}
            spaceBetween={24}
            slidesPerView={1}
            slidesPerGroup={1}
            loop={isLoopAllow}
            loopFillGroupWithBlank={isLoopAllow}
            breakpoints={{
              575.98: {
                slidesPerView: 2,
              },
              1199.98: {
                slidesPerView: 3,
              },
            }}
          >
            {productsData &&
              productsData.items.map((product, idx) => (
                <SwiperSlide key={idx.toString()}>
                  <ModuleCard
                    imagePath={product.imagePath}
                    title={product.title}
                    dayPrice={product.dayPrice}
                    accessType={product.accessType}
                    subscription={product.subscription}
                    category={product.category.title}
                    countModule={product.modulesCount}
                    countLesson={product.lessonsCount}
                    slug={product.slug}
                    isLoggedIn={isLoggedIn}
                    initialAmounts={product.initialAmounts}
                    fullPrice={product.fullPrice}
                    categorySlug={product.category.slug}
                    currency={product.currency}
                  />
                </SwiperSlide>
              ))}
          </StyledCatalogueSwiper>
          {/* for mobile */}
          <StyledCatalogueModuleCardWrapper>
            {productsData && productsData.items[0] && (
              <ModuleCard
                imagePath={productsData.items[0].imagePath}
                title={productsData.items[0].title}
                dayPrice={productsData.items[0].dayPrice}
                accessType={productsData.items[0].accessType}
                subscription={productsData.items[0].subscription}
                category={productsData.items[0].category.title}
                countModule={productsData.items[0].modulesCount}
                countLesson={productsData.items[0].lessonsCount}
                slug={productsData.items[0].slug}
                isLoggedIn={isLoggedIn}
                initialAmounts={productsData.items[0].initialAmounts}
                fullPrice={productsData.items[0].fullPrice}
                categorySlug={productsData.items[0].category.slug}
                currency={productsData.items[0].currency}
              />
            )}

            {productsData && productsData.items[1] && (
              <ModuleCard
                imagePath={productsData.items[1].imagePath}
                title={productsData.items[1].title}
                dayPrice={productsData.items[1].dayPrice}
                accessType={productsData.items[1].accessType}
                subscription={productsData.items[1].subscription}
                category={productsData.items[1].category.title}
                countModule={productsData.items[1].modulesCount}
                countLesson={productsData.items[1].lessonsCount}
                slug={productsData.items[1].slug}
                isLoggedIn={isLoggedIn}
                initialAmounts={productsData.items[1].initialAmounts}
                fullPrice={productsData.items[1].fullPrice}
                categorySlug={productsData.items[1].category.slug}
                currency={productsData.items[1].currency}
              />
            )}
          </StyledCatalogueModuleCardWrapper>

          <Link href='/catalogue' passHref>
            <StyledButton>
              {t('row-8.catalogue')}
              <StyledIcon>
                <Icon name='arrow-right' />
              </StyledIcon>
            </StyledButton>
          </Link>
        </StyledCatalogue>
      </Container>
      {/* new row - 9 Пропустим пока */}

      {/* new row - 10  */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <Container as={'section'}>
          <StyledLicenceBlock>
            <StyledLicenceBlockText>
              <StyledLicenceCaption>{t('row-10.caption')}</StyledLicenceCaption>
              <StyledTitle>
                <StyledLicenceTitleTop>
                  {t('row-10.title-1')}
                </StyledLicenceTitleTop>
                <StyledLicenceTitleBottom>
                  {t('row-10.title-2')}
                </StyledLicenceTitleBottom>
              </StyledTitle>

              <StyledLicenceParagraph>
                {t('row-10.description')}
              </StyledLicenceParagraph>
            </StyledLicenceBlockText>

            <StyledLicenceBlockGif>
              <Image
                alt='phone'
                width={390}
                height={580}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-10-phone-new.webp`}
              />
            </StyledLicenceBlockGif>

            <StyledLicenceBlockImg>
              <Image
                alt='phone'
                width={345}
                height={400}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-10-phone-mob.svg`}
                unoptimized
              />
            </StyledLicenceBlockImg>

            <StyledLicenceBlockBgDecor>
              <Image
                alt='background'
                width={559}
                height={339}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-10-bg-decor.svg`}
              />
            </StyledLicenceBlockBgDecor>
          </StyledLicenceBlock>
        </Container>
      </Hide>

      {/* new row - 11 reviews */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <StyledReviewsContainer>
          <Container>
            <StyledMiniTitle>{t('row-11.subtitle')}</StyledMiniTitle>
            <StyledTitle>
              <Trans
                t={t}
                i18nKey={'row-11.title'}
                components={{ '1': <StyledAccent as={'span'} /> }}
              />
              <StyledPulse>
                &gt; 76523
                <StyledPulseAccent>Довольных участников</StyledPulseAccent>
              </StyledPulse>
            </StyledTitle>

            {isClientSide && (
              <StyledReviewsSwiper
                modules={[Controller, Navigation, Pagination, Autoplay]}
                controller={{ control: swiperReviews }}
                onSwiper={setSwiperReviews}
                spaceBetween={24}
                slidesPerView={1}
                breakpoints={{
                  743.98: {
                    slidesPerView: 2,
                  },
                }}
                onSlideChange={swiper =>
                  setReviewsActiveIndex(swiper.activeIndex - 2)
                }
                loop
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                {reviews.map((review, index) => (
                  <SwiperSlide key={index.toString()}>
                    <StyledReviewItem>
                      <StyledReviewItemHeader>
                        <StyledReviewItemHeaderProfile>
                          <StyledReviewItemHeaderProfileImage>
                            <Image
                              alt={review.name}
                              width={79}
                              height={79}
                              src={review.profile}
                            />
                          </StyledReviewItemHeaderProfileImage>
                          <StyledReviewItemHeaderProfileInformation>
                            <StyledReviewItemName>
                              {review.name}
                            </StyledReviewItemName>
                            <StyledReviewItemCourse>
                              Курс:{' '}
                              <StyledReviewItemCourseAccent>
                                &quot;{review.course}&quot;
                              </StyledReviewItemCourseAccent>
                            </StyledReviewItemCourse>
                          </StyledReviewItemHeaderProfileInformation>
                        </StyledReviewItemHeaderProfile>

                        <StyledReviewItemHeaderStar>
                          {Array.from({ length: 5 }).map((star, index) => (
                            <StyledReviewItemHeaderStarWrapper
                              key={index.toString()}
                            >
                              <Image
                                alt='star'
                                width={18}
                                height={18}
                                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-star.png`}
                              />
                            </StyledReviewItemHeaderStarWrapper>
                          ))}
                        </StyledReviewItemHeaderStar>
                      </StyledReviewItemHeader>

                      <StyledReviewItemContent>
                        {review.review}
                      </StyledReviewItemContent>

                      {review.from && (
                        <StyledReviewFrom href={review.link}>
                          {review.from}
                        </StyledReviewFrom>
                      )}
                    </StyledReviewItem>
                  </SwiperSlide>
                ))}
              </StyledReviewsSwiper>
            )}

            {isClientSide && (
              <StyledReviewControl>
                {/* previous btn */}
                <StyledReviewBtnReserve
                  onClick={() => swiperReviews?.slidePrev(800)}
                  aria-label='previous'
                >
                  <StyledReviewBtnIcon />
                </StyledReviewBtnReserve>
                {/* bullets */}
                {swiperReviews && swiperReviews.loopedSlides && (
                  <StyledBulletContainer>
                    {Array.from(
                      {
                        length:
                          swiperReviews.slides.length -
                          swiperReviews?.loopedSlides * 2,
                      },
                      (v, k) => (
                        <StyledBullet
                          key={k.toString()}
                          isActive={reviewsActiveIndex === k}
                        />
                      ),
                    )}
                  </StyledBulletContainer>
                )}
                {/* next btn */}
                <ButtonDefault
                  onClick={() => {
                    swiperReviews?.slideNext(800)
                  }}
                  aria-label='next'
                >
                  <StyledReviewBtnIcon />
                </ButtonDefault>
              </StyledReviewControl>
            )}
          </Container>

          <StyledReviewBackground>
            <Image
              alt='global'
              width={1020}
              height={512}
              src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-11-global.webp`}
            />
          </StyledReviewBackground>
        </StyledReviewsContainer>
      </Hide>

      {/* new row - 12 certification */}
      <StyledCertificateWrapper>
        <Container>
          <StyledCerificateHeadingBlock>
            <StyledCertificateTitle>
              <Trans
                t={t}
                i18nKey={'row-12.title'}
                components={{ '1': <StyledAccent as={'span'} /> }}
              />
            </StyledCertificateTitle>
            <StyledCertificateTitleText>
              {t('row-12.description')}
            </StyledCertificateTitleText>
            <StyledCertificateTitleDecor>
              <Image
                alt='background'
                width={153.66}
                height={185.03}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-12-title-decor.svg`}
              />
            </StyledCertificateTitleDecor>
          </StyledCerificateHeadingBlock>

          <StyledCertificateContentBlock ref={refSvgLine}>
            <StyledCertificateContentLeft>
              <StyledCertificateLeftHeading>
                {t('row-12.without-cert')}
              </StyledCertificateLeftHeading>
              <StyledCertificateLeftPrice>
                {t('row-12.without-cert-price')}
              </StyledCertificateLeftPrice>
              <StyledCertificateLeftImgMan>
                <Image
                  alt='man'
                  width={535.14}
                  height={332}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-12-man-no-diplom.png`}
                />
              </StyledCertificateLeftImgMan>
            </StyledCertificateContentLeft>

            <StyledCertificateContentRight>
              <StyledCertificateRightHeading>
                {t('row-12.cert')}
              </StyledCertificateRightHeading>

              <StyledCerificateRightCaption>
                <Trans
                  t={t}
                  i18nKey={'row-12.with-cert'}
                  components={{ '1': <StyledCertificateRightSpan /> }}
                />
              </StyledCerificateRightCaption>
              <StyledCertificateRightDiplom>
                <Image
                  alt='certificate'
                  width={473.3}
                  height={413.37}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-12-certificate.png`}
                />
              </StyledCertificateRightDiplom>
            </StyledCertificateContentRight>

            <StyledCertificateSvgLineDashed
              viewBox='-20 0 900 240'
              version='1.1'
              id='svg1033'
            >
              <defs id='defs1030' />
              <g id='layer1' transform='translate(-20.472293,-22.027827)'>
                <g id='g484' transform='translate(11.886667,6.306109)'>
                  {isInViewSvgLine && (
                    <motion.path
                      initial={{ pathLength: 0 }}
                      animate={{ pathLength: 1 }}
                      transition={{
                        pathLength: {
                          delay: 0,
                          type: 'tween',
                          duration: 4,
                          bounce: 0,
                        },
                      }}
                      stroke='#18A866'
                      strokeDasharray='3.846, 1.282'
                      strokeDashoffset='0'
                      strokeWidth='2'
                      style={{
                        fill: 'none',
                        fillRule: 'evenodd',
                        strokeLinejoin: 'round',
                      }}
                      d='M59.4439 84.5523C35.4434 113.552 -33.5581 48.038 24.9425 9.05041C84.2297 -30.4614 182.124 106.213 211.838 168.048C239.945 226.537 407.213 226.872 396.339 168.048C388.944 128.049 331.839 127.478 326.839 168.048C321.839 208.618 366.339 292.549 447.339 249.049C528.339 205.549 698.338 -32.9514 854.338 108.049'
                      id='path1154'
                    />
                  )}
                  <path
                    stroke='#f3f4f6'
                    strokeDasharray='10, 10'
                    strokeDashoffset='0'
                    strokeWidth='3'
                    style={{
                      fill: 'none',
                      fillRule: 'evenodd',
                      strokeLinejoin: 'round',
                    }}
                    d='M59.4439 84.5523C35.4434 113.552 -33.5581 48.038 24.9425 9.05041C84.2297 -30.4614 182.124 106.213 211.838 168.048C239.945 226.537 407.213 226.872 396.339 168.048C388.944 128.049 331.839 127.478 326.839 168.048C321.839 208.618 366.339 292.549 447.339 249.049C528.339 205.549 698.338 -32.9514 854.338 108.049'
                    id='path1155'
                  />
                </g>
              </g>
            </StyledCertificateSvgLineDashed>
          </StyledCertificateContentBlock>
        </Container>
      </StyledCertificateWrapper>

      {/* new row - 13 */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <StyledBlog ref={articleRef}>
          <StyledBlogContainer>
            <StyledBlogHeader>
              <StyledBlogTitle>
                <Trans
                  t={t}
                  i18nKey={'row-13.title'}
                  components={{ '1': <StyledBlogTitleAccent as={'span'} /> }}
                />
              </StyledBlogTitle>

              <StyledBlogDescription>
                {t('row-13.description')}
              </StyledBlogDescription>

              <Link href='/blog' passHref>
                <StyledBlogButton>
                  {t('row-13.button')}
                  <StyledIcon>
                    <Icon name='arrow-right' />
                  </StyledIcon>
                </StyledBlogButton>
              </Link>
            </StyledBlogHeader>
            <StyledBlogArticlesDesktop1 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={350}
                height={622}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-1.png`}
              />
            </StyledBlogArticlesDesktop1>

            <StyledBlogArticlesDesktop2 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={160}
                height={190}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-2.png`}
              />
            </StyledBlogArticlesDesktop2>

            <StyledBlogArticlesDesktop3 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={190}
                height={230}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-3.png`}
              />
            </StyledBlogArticlesDesktop3>

            <StyledBlogArticlesDesktop4 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={200}
                height={240}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-4.png`}
              />
            </StyledBlogArticlesDesktop4>

            <StyledBlogArticlesDesktop5 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={200}
                height={240}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-5.png`}
              />
            </StyledBlogArticlesDesktop5>
          </StyledBlogContainer>
          {/* for mobile */}
          <StyledBlogArticles>
            <StyledBlogArticles1 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={350}
                height={622}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-1-mobile.png`}
              />
            </StyledBlogArticles1>

            <StyledBlogArticles2 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={69}
                height={81}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-2.png`}
              />
            </StyledBlogArticles2>

            <StyledBlogArticles3 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={111}
                height={131}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-3.png`}
              />
            </StyledBlogArticles3>

            <StyledBlogArticles5 isViewPort={isInViewArticleBlock}>
              <Image
                alt='article'
                width={123}
                height={145}
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-article-5.png`}
              />
            </StyledBlogArticles5>
          </StyledBlogArticles>

          <StyledBlogLogo ref={logoRef}>
            <StyledBlogLogoContainer>
              <StyledBlogLogoLetterW isViewPort={isInViewLogoBlock}>
                <Image
                  alt='w'
                  width={140}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-w.png`}
                />
              </StyledBlogLogoLetterW>

              <StyledBlogLogoLetterI isViewPort={isInViewLogoBlock}>
                <Image
                  alt='i'
                  width={23}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-i.png`}
                />
              </StyledBlogLogoLetterI>

              <StyledBlogLogoLetterL isViewPort={isInViewLogoBlock}>
                <Image
                  alt='l'
                  width={69}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-l.png`}
                />
              </StyledBlogLogoLetterL>

              <StyledBlogLogoLetterL isViewPort={isInViewLogoBlock}>
                <Image
                  alt='l'
                  width={69}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-l.png`}
                />
              </StyledBlogLogoLetterL>

              <StyledBlogLogoLetterS isViewPort={isInViewLogoBlock}>
                <Image
                  alt='s'
                  width={76}
                  height={108}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-s.png`}
                />
              </StyledBlogLogoLetterS>

              <StyledBlogLogoLetterK isViewPort={isInViewLogoBlock}>
                <Image
                  alt='k'
                  width={85}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-k.png`}
                />
              </StyledBlogLogoLetterK>

              <StyledBlogLogoLetterI isViewPort={isInViewLogoBlock}>
                <Image
                  alt='i'
                  width={23}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-i.png`}
                />
              </StyledBlogLogoLetterI>

              <StyledBlogLogoLetterL isViewPort={isInViewLogoBlock}>
                <Image
                  alt='l'
                  width={69}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-l.png`}
                />
              </StyledBlogLogoLetterL>

              <StyledBlogLogoLetterL isViewPort={isInViewLogoBlock}>
                <Image
                  alt='l'
                  width={69}
                  height={105}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-13-l.png`}
                />
              </StyledBlogLogoLetterL>
            </StyledBlogLogoContainer>
          </StyledBlogLogo>
        </StyledBlog>
      </Hide>
      {/* new row - 14 */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <StyledLearnFreeWrapper>
          <Container>
            <StyledLearnFreeTitle>
              <Trans
                t={t}
                i18nKey={'row-14.title'}
                components={{
                  '1': <StyledLearnFreeTitleSpan />,
                  '2': <StyledLearnFreeTitleSpan2 />,
                }}
              />
            </StyledLearnFreeTitle>

            <StyledLearnFreeCaption as={'p'}>
              {t('row-14.subtitle')}
            </StyledLearnFreeCaption>

            <StyledLearnFreeContent>
              <StyledLearnFreeContentBg>
                <Image
                  alt='arrow'
                  width={1224}
                  height={395}
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-bg-content.png`}
                />
              </StyledLearnFreeContentBg>
              <StyledLearnFreeTextBlock>
                <StyledLearnFreeTextBlockHeading>
                  <Trans
                    t={t}
                    i18nKey={'row-14.heading'}
                    components={{
                      '1': <StyledLearnFreeTextBlockHeadingSpan />,
                    }}
                  />
                </StyledLearnFreeTextBlockHeading>
                <Link href={'/#catalogue'} passHref>
                  <StyledLearnFreeTextBlockBtn>
                    {t('row-14.button')}
                    <StyledIcon>
                      <Icon name='arrow-right' />
                    </StyledIcon>
                  </StyledLearnFreeTextBlockBtn>
                </Link>
              </StyledLearnFreeTextBlock>

              {/* slider */}

              <StyledLearnFreeGalleryCircle>
                <Image
                  src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-circle.png`}
                  alt='circle'
                  width={486}
                  height={486}
                />
              </StyledLearnFreeGalleryCircle>

              <StyledLearnFreeGallery>
                <StyledLearnFreeImg>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-slide-1-cropped.png`}
                    alt='photo'
                    width={526}
                    height={349}
                  />
                  <Link href={`/other/kak-ustroitsa-na-rabotu-mechti`} passHref>
                    <StyledLearnFreeImgLink>
                      {t('row-14.course-1')}
                    </StyledLearnFreeImgLink>
                  </Link>
                  <Link href={`/other/kak-ustroitsa-na-rabotu-mechti`} passHref>
                    <StyledLearnFreeImgLinkArrow>
                      <Image
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-arrow-icon.svg`}
                        alt='icon'
                        width={50}
                        height={16}
                      />
                    </StyledLearnFreeImgLinkArrow>
                  </Link>
                </StyledLearnFreeImg>

                <StyledLearnFreeImg>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-slide-2-cropped.png`}
                    alt='work'
                    width={526.31}
                    height={349}
                  />
                  <Link href={`/other/fotografiya-s-nulya`} passHref>
                    <StyledLearnFreeImgLink>
                      {t('row-14.course-2')}
                    </StyledLearnFreeImgLink>
                  </Link>
                  <Link href={`/other/fotografiya-s-nulya`} passHref>
                    <StyledLearnFreeImgLinkArrow>
                      <Image
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-arrow-icon.svg`}
                        alt='icon'
                        width={50}
                        height={16}
                      />
                    </StyledLearnFreeImgLinkArrow>
                  </Link>
                </StyledLearnFreeImg>

                <StyledLearnFreeImg>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-slide-1-cropped.png`}
                    alt='photo'
                    width={526}
                    height={349}
                  />
                  <Link href={`/other/kak-ustroitsa-na-rabotu-mechti`} passHref>
                    <StyledLearnFreeImgLink>
                      {t('row-14.course-1')}
                    </StyledLearnFreeImgLink>
                  </Link>
                  <Link href={`/other/kak-ustroitsa-na-rabotu-mechti`} passHref>
                    <StyledLearnFreeImgLinkArrow>
                      <Image
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-arrow-icon.svg`}
                        alt='icon'
                        width={50}
                        height={16}
                      />
                    </StyledLearnFreeImgLinkArrow>
                  </Link>
                </StyledLearnFreeImg>

                <StyledLearnFreeImg>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-slide-2-cropped.png`}
                    alt='work'
                    width={526.31}
                    height={349}
                  />
                  <Link href={`/other/fotografiya-s-nulya`} passHref>
                    <StyledLearnFreeImgLink>
                      {t('row-14.course-2')}
                    </StyledLearnFreeImgLink>
                  </Link>
                  <Link href={`/other/fotografiya-s-nulya`} passHref>
                    <StyledLearnFreeImgLinkArrow>
                      <Image
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-14-arrow-icon.svg`}
                        alt='icon'
                        width={50}
                        height={16}
                      />
                    </StyledLearnFreeImgLinkArrow>
                  </Link>
                </StyledLearnFreeImg>
              </StyledLearnFreeGallery>
            </StyledLearnFreeContent>
          </Container>
        </StyledLearnFreeWrapper>
      </Hide>
      {/* new row - 15, comments video */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <StyledCommentsContainer>
          <Container>
            <StyledComments>
              <StyledDescription>{t('row-15.description')}</StyledDescription>
              <StyledTitle>
                <Trans
                  t={t}
                  i18nKey='row-15.title'
                  components={{ 1: <StyledAccent /> }}
                />
              </StyledTitle>
              <Comments />
            </StyledComments>
          </Container>
        </StyledCommentsContainer>
      </Hide>

      {/* new row - 16 Cooperate with leading companies */}

      {/*<Hide hide={JSON.parse(INTERNALIZATION) as boolean}>*/}
      {/*  <StyledCooperateContainer>*/}
      {/*    <Container>*/}
      {/*      <StyledCooperate>*/}
      {/*        <StyledCooperateTitle>*/}
      {/*          Cотрудничаем*/}
      {/*          <StyledTitleNextLine>*/}
      {/*            <StyledAccent>с ведущими</StyledAccent> компаниями*/}
      {/*          </StyledTitleNextLine>*/}
      {/*        </StyledCooperateTitle>*/}

      {/*        <StyledCooperateIcons>*/}
      {/*          {cooperateIcons.map(icon => (*/}
      {/*            <StyledCooperateIcon key={icon.alt}>*/}
      {/*              <Image*/}
      {/*                alt={icon.alt}*/}
      {/*                src={icon.url}*/}
      {/*                width={200}*/}
      {/*                height={48}*/}
      {/*              />*/}
      {/*            </StyledCooperateIcon>*/}
      {/*          ))}*/}
      {/*        </StyledCooperateIcons>*/}
      {/*      </StyledCooperate>*/}
      {/*    </Container>*/}
      {/*  </StyledCooperateContainer>*/}
      {/*</Hide>*/}

      {/* new row - 17 */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <StyledCheckCert>
          <StyledCheckCertMobile>
            <Image
              src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-bg-mobile.png`}
              layout='fill'
              alt='background'
              objectFit='cover'
            />
          </StyledCheckCertMobile>
          <StyledCheckCertDesktop>
            <Image
              src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-bg.png`}
              layout='fill'
              alt='background'
              objectFit='cover'
            />
          </StyledCheckCertDesktop>
          <Container>
            <StyledCheckCertContainer>
              <StyledCheckCertWrapper>
                <StyledCheckCertCircleContainer>
                  <StyledCheckCertCircle
                    target='_blank'
                    rel='noreferrer'
                    href={`${API_URL}${LICENSE}`}
                  >
                    <Trans t={t} i18nKey={'row-17.circle'} />
                  </StyledCheckCertCircle>

                  <StyledCheckCertCircleShadow />
                  <StyledCheckStart1>
                    <Image
                      alt='star'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-star.png`}
                      width={48.2}
                      height={48.2}
                    />
                  </StyledCheckStart1>
                  <StyledCheckStart2>
                    <Image
                      alt='star'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-star.png`}
                      width={48.2}
                      height={48.2}
                    />
                  </StyledCheckStart2>

                  <StyledCheckStart3>
                    <Image
                      alt='star'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-star.png`}
                      width={48.2}
                      height={48.2}
                    />
                  </StyledCheckStart3>

                  <StyledCheckStart4>
                    <Image
                      alt='star'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-star.png`}
                      width={48.2}
                      height={48.2}
                    />
                  </StyledCheckStart4>

                  <StyledCheckStart5>
                    <Image
                      alt='star'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-17-star.png`}
                      width={48.2}
                      height={48.2}
                    />
                  </StyledCheckStart5>
                </StyledCheckCertCircleContainer>

                <StyledCheckCertCircleText>
                  {logoString.split('').map((char, i) => (
                    <StyledCheckCertCircleTextLetter
                      key={i.toString()}
                      index={i}
                    >
                      {char}
                    </StyledCheckCertCircleTextLetter>
                  ))}
                </StyledCheckCertCircleText>
              </StyledCheckCertWrapper>
            </StyledCheckCertContainer>

            <StyledCheckGirl>
              <Image
                alt='girl'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-17-girl.gif`}
                width={381}
                height={381}
              />
            </StyledCheckGirl>

            <StyledCheckTodo>
              <Image
                alt='todo'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-17-todo.gif`}
                width={324}
                height={301}
              />
            </StyledCheckTodo>
          </Container>
        </StyledCheckCert>
      </Hide>
      {/* new row - 18 */}
      <Hide hide>
        <Container as={'section'}>
          <StyledFormBlock>
            <StyledFormBlockDecorSmall>
              <Image
                width={249}
                height={249}
                alt='decor'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-18-circle.svg`}
              />
            </StyledFormBlockDecorSmall>

            <StyledFormBlockTitle>
              <Trans
                t={t}
                i18nKey={'row-18.title'}
                components={{ '1': <StyledAccent as={'span'} /> }}
              />
            </StyledFormBlockTitle>

            <StyledFormBlockCaption>
              {t('row-18.subtitle')}
            </StyledFormBlockCaption>
            <StyledFormBlockWrapper>
              <StyledFormBlockInput
                type='text'
                name='email'
                placeholder={t('row-18.placeholder')}
              />
              <StyledFormBlockBtn>
                {t('row-18.button')}
                <StyledFormBlockBtnIcon>
                  <Image
                    width={24}
                    height={24}
                    alt='icon'
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-18-btn-icon.svg`}
                  />
                </StyledFormBlockBtnIcon>
              </StyledFormBlockBtn>
            </StyledFormBlockWrapper>

            <StyledFormBlockAgreementCaption>
              <Trans
                t={t}
                i18nKey={'row-18.comments'}
                components={{
                  '1': (
                    <StyledFormBlockAgreementLink
                      href={PRIVACY_POLICY}
                      target='_blank'
                      rel='noreferrer'
                      className='checkbox__link order__link'
                    />
                  ),
                  '2': (
                    <StyledFormBlockAgreementLink
                      href={PERSONAL_DATA}
                      target='_blank'
                      rel='noreferrer'
                      className='checkbox__link order__link'
                    />
                  ),
                  '3': (
                    <StyledFormBlockAgreementLink
                      href={OFERTA}
                      target='_blank'
                      rel='noreferrer'
                      className='checkbox__link order__link'
                    />
                  ),
                }}
              />
            </StyledFormBlockAgreementCaption>

            <StyledFormBlockPhoneGif>
              <Image
                width={407}
                height={449}
                alt='gif'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/gif/main-row-18-phone.gif`}
              />
            </StyledFormBlockPhoneGif>

            <StyledFormBlockDecorBig>
              <Image
                width={537}
                height={537}
                alt='decor'
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-18-circle.svg`}
              />
            </StyledFormBlockDecorBig>
          </StyledFormBlock>
        </Container>
      </Hide>

      {/* new row - 19 */}
      <Hide hide={JSON.parse(INTERNALIZATION) as boolean}>
        <Container as={'section'}>
          <StyledBrandsContainer ref={brandsRef}>
            <StyledBrandsTitle>
              <Trans
                t={t}
                i18nKey={'row-19.title'}
                components={{ '1': <StyledAccent /> }}
              />
            </StyledBrandsTitle>

            <StyledDescription>{t('row-19.description')}</StyledDescription>

            <StyledBrandsLogosContainer>
              <StyledBrandsLogosInnerContainer>
                <Link href={`/programming/programmirovanie-roblox`} passHref>
                  <StyledBrandsCircleFirst isSpread={isSpread}>
                    <Image
                      width={19}
                      height={19}
                      alt='brand-1'
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-1.svg`}
                    />
                  </StyledBrandsCircleFirst>
                </Link>

                <Link href={`/marketing/target-manager`} passHref>
                  <StyledBrandsCircleSecond isSpread={isSpread}>
                    <StyledBrandsCircleSecondImage>
                      <Image
                        width={29.59}
                        height={29.59}
                        alt='brand-2'
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-2.svg`}
                      />
                    </StyledBrandsCircleSecondImage>
                  </StyledBrandsCircleSecond>
                </Link>

                <Link href={`/crypto/cryptotrading-s-nulya`} passHref>
                  <StyledBrandsCircleThird isSpread={isSpread}>
                    <StyledBrandsCircleThirdImage>
                      <Image
                        width={26}
                        height={39}
                        alt='brand-3'
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-3.svg`}
                      />
                    </StyledBrandsCircleThirdImage>
                  </StyledBrandsCircleThird>
                </Link>

                <Link href={`/language/english-s-nulya`} passHref>
                  <StyledBrandsCircleBorderFourth isSpread={isSpread}>
                    <StyledBrandsCircleBorderFourthImage>
                      <Image
                        width={43.05}
                        height={43.05}
                        alt='brand-4'
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-4.svg`}
                      />
                    </StyledBrandsCircleBorderFourthImage>
                  </StyledBrandsCircleBorderFourth>
                </Link>

                <StyledBrandsCircleBorder
                  onClick={() =>
                    setIsSpread(prev => {
                      if (prev === null) return true
                      return !prev
                    })
                  }
                  as='div'
                >
                  <StyledBrandsCircle>
                    <StyledBrandsCircleInnder>
                      <Icon name='add' width={'19.5'} height={'19.5'} />
                    </StyledBrandsCircleInnder>
                  </StyledBrandsCircle>
                </StyledBrandsCircleBorder>
                <StyledBrandsCircleInvisible />

                <Link href={`/crypto/nft-dlya-cifrovih-hudozhnikov`} passHref>
                  <StyledBrandsCircleBorderSixth isSpread={isSpread}>
                    <StyledBrandsCircleBorderSixthImage>
                      <Image
                        width={53}
                        height={53}
                        alt='brand-5'
                        unoptimized
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-5.svg`}
                      />
                    </StyledBrandsCircleBorderSixthImage>
                  </StyledBrandsCircleBorderSixth>
                </Link>

                <Link href={`/other/cybersportsmen`} passHref>
                  <StyledBrandsCircleSeventh isSpread={isSpread}>
                    <StyledBrandsCircleSeventhImage>
                      <Image
                        width={32.59}
                        height={32.59}
                        alt='brand-6'
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-6.svg`}
                      />
                    </StyledBrandsCircleSeventhImage>
                  </StyledBrandsCircleSeventh>
                </Link>

                <Link href={`/marketing/menedger-marketpleysov`} passHref>
                  <StyledBrandsCircleEight isSpread={isSpread}>
                    <StyledBrandsCircleEightImage>
                      <Image
                        width={32.82}
                        height={32.82}
                        alt='brand-7'
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-7.svg`}
                      />
                    </StyledBrandsCircleEightImage>
                  </StyledBrandsCircleEight>
                </Link>

                <Link href={`/other/time-management`} passHref>
                  <StyledBrandsCircleNineth isSpread={isSpread}>
                    <Image
                      width={24.78}
                      height={26}
                      alt='brand-8'
                      unoptimized
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/main-row-19-brand-8.svg`}
                    />
                  </StyledBrandsCircleNineth>
                </Link>
              </StyledBrandsLogosInnerContainer>
            </StyledBrandsLogosContainer>

            <Link href='/catalogue' passHref>
              <StyledBransSelectButton>
                {t('row-19.button')}
                <StyledIcon>
                  <Icon name='arrow-right' />
                </StyledIcon>
              </StyledBransSelectButton>
            </Link>
          </StyledBrandsContainer>
        </Container>
      </Hide>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
}: GetServerSidePropsContext) => {
  const previousRoute = req.headers?.referer || null
  const queryClient = new QueryClient()
  // await queryClient.prefetchQuery(['getCategories'], categoryApi.getCategories)
  await queryClient.prefetchQuery(
    [
      'getProducts',
      {
        categorySlug: '',
      },
      false,
    ],
    () => productApi.getProducts(),
  )
  try {
    const contents = await mainCMSApi.getMainCMS({
      ...queryMainCMS,
      locale: locale as string,
    })

    return {
      props: {
        ...(await serverSideTranslations(locale as string, [
          'common',
          'components.header',
          'components.footer',
          'components.moduleCard',
          'components.modal',
          'components.navigation',
          'pages.index',
        ])),
        dehydratedState: dehydrate(queryClient),
        data: contents,
        previousRoute,
      },
    }
  } catch (error) {
    return {
      redirect: {
        permanent: false,
        destination: '/404',
      },
    }
  }
}

export default Home
// --------- Styles --------------
// --------- Common --------------
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 1284px;
    padding: 0 30px;
  }
`
const StyledLink = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.mixins.textButton};
  color: #fff;
  background: ${({ theme }) => theme.colors.primary};
  height: 45px;
  border-radius: 50px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  border: 1px solid ${({ theme }) => theme.colors.primary};
  z-index: 11;
  position: relative;
  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textButton2};
  }
`

const StyledDescription = styled.div`
  ${({ theme }) => theme.mixins.textNormal}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H6}
  }
`

const StyledAccent = styled.b`
  color: ${({ theme }) => theme.colors.primary};
`

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: 25px;
  line-height: 30.9px;
  position: relative;
  z-index: 10;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2};
  }
`
// new row - 1
const StyledHeroContainer = styled.section`
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.02),
    rgba(252, 252, 252, 0.1),
    rgba(252, 252, 252, 0)
  );
`

const StyledRowFirst = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  padding-bottom: 47px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-bottom: 0;
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.alignItems('center')}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 100%;
  }
`

const StyledBackground = styled.div`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
  }
`

const StyledBackgroundMobile = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledRowFirstInformationWrapper = styled.div`
  margin-bottom: 23px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 50%;
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 743px;
  }
`

const StyledRowFirstSubtitle = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  margin-bottom: 8px;
  ${({ theme }) => theme.mixins.textSmall3};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal}
    margin-bottom: 14px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6}
    margin-bottom: 33px;
  }
`
const StyledRowFirstSubtitleTag = styled.div`
  background: #fff;
  border-radius: 17.22px;
  border: 0.86px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  width: 63px;
  height: 18px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  margin-right: 10.33px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 108.5px;
    height: 31px;
  }
`

const StyledRowFirstTitleWrapper = styled.h1`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
`

const StyledRowFirstTitleBoldTop = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  line-height: 34.61px;
  & span {
    background-color: white;
    padding-right: 29px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    border-top: 4px solid ${({ theme }) => theme.colors.primary};
    z-index: -1;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H2}
    &:after {
      height: 25px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 572.6px;
    font-weight: 700;
    font-size: 55.3px;
    line-height: 68.34px;
    &:after {
      height: 34.65px;
    }
  }
`
const StyledRowFirstTitleNormal = styled.div`
  font-weight: 400;
  font-size: calc(34px - 15%);
  line-height: calc(42px - 15%);
  letter-spacing: calc(1px - 15%);
  z-index: 11;
  @media ${({ theme }) => theme.devices.mobileXxs} {
    font-size: 34px;
    line-height: 42px;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 43px;
    line-height: 49px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    font-size: 68px;
    line-height: 84.05px;
    letter-spacing: 1.97px;
    padding-left: 51px;
    margin: 10px 0;
  }
`

const StyledRowFirstTitleBoldBottom = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 34.61px;
  position: relative;
  text-transform: uppercase;
  text-align: right;
  z-index: 11;
  width: 100%;
  & span {
    background-color: white;
    padding-left: 25px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    border-top: 4px solid ${({ theme }) => theme.colors.primary};
    z-index: -1;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    align-self: flex-end;
    ${({ theme }) => theme.mixins.H2}
    &::before {
      height: 25px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 574px;
    align-self: flex-end;
    font-weight: 700;
    font-size: 55.3px;
    line-height: 68.34px;
    &::before {
      height: 34.65px;
    }
  }
`

const StyledRowFirstImage = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  position: relative;
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 72px;
    width: 50%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 531px;
    height: 589px;
    ${({ theme }) => theme.mixins.flexShrink(0)}
  }
`

const StyledRowFirstVideo = styled.video`
  position: absolute;
  width: 100%;
  top: -10%;
  left: -2%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: -14%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    top: -15%;
  }
`

const StyledRowFirstButtonWrapper = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledRowFirstButtonWrapperDesktop = styled(StyledRowFirstButtonWrapper)`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
    margin-top: 41px;
    ${({ theme }) => theme.mixins.flexbox()}
    ${({ theme }) => theme.mixins.flexDirection('column')}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 64px;
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.alignItems('center')}
    gap: 24px;
  }
`

const StyledRowFirstButtonContainer = styled.div`
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 351px;
    order: 1;
  }
`
// new row - 2 (with slider)
const StyledWordContainer = styled(MouseParallaxContainer)`
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;
  padding: 87.45px 0 74.55px;
  position: relative;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding: 91px 0;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 140px 0;
  }
`

const StyledWordLogo = styled(MouseParallaxChild)`
  max-width: 72.65px;
  width: 100%;
  max-height: 72.65px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 77px;
    max-height: 77px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 162px;
    max-height: 162px;
  }
`

const StyledWordLogo1 = styled(StyledWordLogo)`
  position: absolute;
  top: 7%;
  left: 7%;
  width: 109px;
  height: 109px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    top: 13%;
  }
`

const StyledWordLogo2 = styled(StyledWordLogo)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 2%;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    top: 7%;
  }
`

const StyledWordLogo3 = styled(StyledWordLogo)`
  position: absolute;
  top: 5%;
  right: 7%;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    top: 6%;
  }
`

const StyledWordLogo4 = styled(StyledWordLogo)`
  position: absolute;
  bottom: -2%;
  left: 25%;
`

const StyledWordLogo5 = styled(StyledWordLogo)`
  position: absolute;
  bottom: -5%;
  right: 25%;
`

const StyledWordRow = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  position: relative;
`

const StyledWordRowBlock = styled.div`
  position: relative;
  min-width: 730px;

  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  animation: ${({ theme }) => theme.keyframes.toLeft} 7s infinite linear;
  z-index: 100;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    min-width: 900px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    min-width: 1300px;
  }
`

const StyledWordRowBlockText = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  white-space: nowrap;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    font-size: 27px;
    line-height: 33px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    font-size: 40px;
    line-height: 49px;
    height: 50px;
  }
`

// new row - 3
const StyledSelectContainer = styled.section`
  position: relative;
  padding: 24px 0;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 40px 0;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 40px 0;
  }
`

const StyledSelect = styled.div`
  display: grid;
  grid-row-gap: 40px;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    grid-column-gap: 56px;
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    grid-column-gap: 85px;
    grid-template-columns: 9fr 7.916fr;
  }
`

const StyledSelectFirstColumn = styled.h2`
  ${({ theme }) => theme.mixins.H3}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
    top: 100px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H1}
  }
`

const StyledSelectSecondColumn = styled.ul``

const StyledSelectSecondColumnAccent = styled(StyledAccent)`
  position: relative;
  display: block;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 14px;
    ${({ theme }) => theme.mixins.H2}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 17px;
    ${({ theme }) => theme.mixins.H1}
  }
`
const StyledSelectSecondColumnArrowMobile = styled.div`
  position: absolute;
  top: 5px;
  left: 120px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledSelectSecondColumnArrow = styled.div`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 159px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 297px;
  }
`

const StyledSelectItemContainer = styled.li`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 80px;
  }
`

const StyledSelectItemImage = styled.div`
  width: 64px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 70px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 81px;
  }
`

const StyledSelectItemImageSecond = styled(StyledSelectItemImage)`
  width: 94px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 110px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 137px;
  }
`
const StyledSelectItemTitle = styled.h3`
  margin-top: 18px;
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 32px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

const StyledSelectItemDescription = styled.p`
  margin-top: 18px;
  ${({ theme }) => theme.mixins.textNormal}

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6}
  }
`

//new Row 4
const StyledSectionVideoBlock = styled.section`
  margin: 64px auto 32px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin: 88px auto 32px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin: 40px auto 40px;
  }
`

const StyledVideoContainer = styled.div`
  overflow: hidden;
  border-radius: 30px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 40px;
  }
`

const StyledVideoThumbnail = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #1a4a35, #39a462, #39a462);
`

const StyledVideoPlayerWrapper = styled.div`
  border-radius: 30px;
  overflow: hidden;
  max-height: 574px;
`

const StyledVideoThumbnailTitle = styled.h2`
  position: absolute;
  top: calc(50% - 20px);
  left: 7px;
  z-index: 3;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  max-width: 120px;
  color: #fff;
  text-transform: uppercase;

  @media ${({ theme }) => theme.devices.mobileXsUp} {
    top: calc(50% - 28px);
    left: 10px;
    font-size: 20px;
    line-height: 25px;
    max-width: 150px;
  }

  @media (min-width: 419px) {
    top: calc(50% - 28px);
    left: 20px;
    font-size: 20px;
    line-height: 25px;
    max-width: 150px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: calc(50% - 41px);
    left: 45px;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    max-width: 210px;
  }

  @media ${({ theme }) => theme.devices.ipadUp} {
    top: calc(50% - 53px);
    left: 55px;
    font-weight: 500;
    font-size: 42px;
    line-height: 53px;
    max-width: 290px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: calc(50% - 69px);
    left: 80px;
    font-weight: 600;
    font-size: 56px;
    line-height: 67px;
    max-width: 420px;
  }
`

const StyledVideoOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.flexShrink(0)}
`

const StyledButtonVideo = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.transition('0.3s all')}
  &:hover {
    ${({ theme }) =>
      theme.mixins.boxShadow('0px 14px 24px rgba(24, 168, 102, 0.4)')}
  }
  /* display: none; */

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 95px;
    height: 95px;
  }
`

const StyledTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 24px solid #191919;
  border-radius: 2px;
  ${({ theme }) => theme.mixins.translate('4px', '0')};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 30px solid ${({ theme }) => theme.colors.black};
  }
`

// new row - 5
const StyledReason = styled.ul<{ internalization: boolean }>`
  background-color: #f3f4f6;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: calc(18px + 47px);
  padding: calc(23px + 47px) 15px 23px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    grid-column-gap: 24px;
    overflow-x: auto;
    padding: calc(24px + 42px) 17.5px 24px;
    grid-template-columns: ${({ internalization }) =>
      internalization ? '1fr 1fr' : '1fr 1fr 1fr'};
    grid-template-rows: 1fr;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    grid-column-gap: 48px;
    padding: calc(24px + 72px) 17.5px 24px;
  }
`
const StyledReasonItem = styled.li`
  border: 1.03846px solid #f3f4f6;
  background-color: #fff;
  border-radius: 14.01px;
  position: relative;
  z-index: 3;
  box-shadow: -3.11538px 2.07692px 23.8846px rgba(0, 0, 0, 0.05);
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  padding: 7px 6px;
  gap: 14px;
  &::before {
    content: attr(data-value);
    position: absolute;
    top: 0;
    left: 50%;
    white-space: pre;
    transform: translate(-50%, -48px);
    color: #fff;
    font-weight: 700;
    font-size: 60px;
    z-index: 2;
  }
  @media ${({ theme }) => theme.devices.mobileMdUp} {
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    &::before {
      content: attr(data-value);
      transform: translate(-50%, -62px);
      font-size: 80px;
    }
  }
`

const StyledReasonItemImage = styled.div`
  position: relative;
  width: 51px;
  ${({ theme }) => theme.mixins.flexbox()}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 40px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 83px;
  }
`

const StyledReasonItemText = styled.div`
  ${({ theme }) => theme.mixins.textButton}
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H5}
  }
`

//new Row - 6

const StyledBenefitRowWrapper = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  margin: 64px auto;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
  }
`

const StyledBenefitRowLeft = styled.div`
  width: 100%;
  margin-bottom: 32px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    min-width: 330px;
    margin-right: 22px;
    margin-bottom: 0px;
  }
`
const StyledBenefitRowTitle = styled.h2`
  font-size: 24.43px;
  line-height: 30.19px;
  font-weight: 600;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H3}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
  }
`

const StyledBenefitRowCaption = styled.div`
  font-weight: 700;
  font-size: 24.03px;
  line-height: 29.7px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: end;
  position: relative;
  max-width: 425px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H3}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H1}
    max-width: 100%;
  }
`

const StyledBenefitRowDecorationLine = styled.div`
  max-width: 141px;
  max-height: 12px;
  position: absolute;
  right: 5px;
  bottom: 0px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 239px;
    max-height: 20px;
    right: -17px;
    bottom: -11px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 294px;
    max-height: 24px;
    right: 21px;
    bottom: 2px;
  }
`

const StyledBenefitRowText = styled.p`
  margin-top: 37px;
  margin-bottom: 40px;
  ${({ theme }) => theme.mixins.textNormal}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-top: 58px;
    ${({ theme }) => theme.mixins.textSmall}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6}
  }
`

const StyledBenefitRowPriceBlockTest = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  border-radius: 36px;
  max-width: 290px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 343px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 420px;
  }
`
const StyledBenefitPriceBlockWrapper = styled.div`
  word-wrap: wrap;
  max-width: 145px;
  ${({ theme }) => theme.mixins.textSmall3}
  padding: 6px 18.5px;
  text-align: center;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 16px;
    line-height: 25px;
    max-width: 170px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H6}
    max-width: 100%;
  }
`

const StyledBenefitRowPriceTest = styled.div`
  border-radius: 36px;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
`

const StyledBenefitRowPriceTestSpan = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  word-wrap: wrap;
  max-width: 150px;
  padding: 6px 18.5px;
  color: #fff;
  text-align: center;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 16px;
    line-height: 25px;
    max-width: 170px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
  }
`

const StyledBenefitFootnote = styled.p`
  margin-top: 18px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black3};

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 14px;
  }
`

const StyledBenefitRowRight = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    min-width: 330px;
    width: 100%;
  }
`

const StyledBenefitMessageBlock = styled.div<{
  isView: boolean
  delay: number
}>`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('flex-start')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  gap: 6px;
  margin-bottom: 16px;
  transform: ${({ isView }) => (isView ? 'opacity(0)' : 'opacity(1)')};
  opacity: ${({ isView }) => (isView ? 1 : 0)};
  transition: all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1)
    ${({ delay }) => delay}s;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &:nth-child(even) {
    ${({ theme }) => theme.mixins.justifyContent('flex-end')}
  }
`

const StyledBenefitMessageAvatar = styled.div`
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 70px;
    min-width: 70px;
    max-height: 70px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 90px;
    min-width: 90px;
    max-height: 90px;
  }
`

const StyledBenefitMessageTextBlue = styled.div`
  max-width: 220px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  border-radius: 20px;
  padding: 7px 14px;
  background-color: ${({ theme }) => theme.colors.blue};
  color: #fff;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 284px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 18px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 412px;
    padding: 13px 26px;
  }
`

const StyledBenefitMessageTextGray = styled.div`
  max-width: 140px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  border-radius: 20px;
  padding: 7px 14px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: #000;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 284px;
    font-size: 18px;
    line-height: 22px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 386px;
    padding: 13px 26px;
  }
`

// new row - 7
const StyledHowWork = styled.section`
  background-color: #f3f3f6;
  border-radius: 20px;
  padding: 32px 15px 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 21px;
  position: relative;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    grid-template-columns: 9fr 5.281fr;
    grid-template-rows: 1fr;
    grid-column-gap: 21px;
    grid-row-gap: 0;
    padding: 32px 37px 32px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 67px 54px 97px;
    grid-template-columns: 9fr 8.2fr;
    grid-column-gap: 106px;
    max-width: calc(1284px - 30px);
    margin: 0 auto;
  }
`
const StyledHowWorkSign = styled.div`
  position: absolute;
  z-index: 3;
  transform: rotate(-2deg);
  display: none;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
    width: 232px;
    bottom: -4%;
    right: 3%;
  }

  @media ${({ theme }) => theme.devices.desktopUp} {
    width: 468px;
    bottom: -4%;
    right: -2%;
  }

  @media (min-width: 1420px) {
    width: 468px;
    bottom: -4%;
    right: -6%;
  }
`

const StyledHowWorkVideoWrapperMob = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  width: 100%;
  @media ${({ theme }) => theme.devices.noteUp} {
    display: none;
  }
`

const StyledHowWorkVideoWrapper = styled.video`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  width: 100%;
  display: none;
  @media ${({ theme }) => theme.devices.noteUp} {
    display: block;
    position: relative;
  }
`

const StyledHowWorkVideoMobile = styled.video`
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const StyledHowWorkLeftSide = styled.div``

const StyledHowWorkList = styled.ul`
  margin-top: 32px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 16px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-top: 40px;
    grid-row-gap: 18px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    grid-row-gap: 24px;
  }
`

const StyledHowWorkListItem = styled.li`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')} /* gap: 19px; */
`

const StyledHowWorkListItemDescription = styled.p`
  ${({ theme }) => theme.mixins.textNormal}
  &[data-ison='false'] {
    filter: blur(1.2px);
    -webkit-filter: blur(1.2px);
  }
  margin-left: 19px;
`

const StyledHowWorkListItemDescriptionStrong = styled.strong`
  font-weight: 700;
  line-height: 25px;
`

const StyledSwitchHandle = styled(motion.div)`
  width: 17.72px;
  height: 18px;
  background-color: #2e2e2e;
  border-radius: 50px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 24px;
    height: 24px;
  }
`

const StyledSwitch = styled.div`
  ${({ theme }) => theme.mixins.flexShrink(0)}
  width: 48px;
  height: 24px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  border-radius: 28px;
  padding: 2px 2.5px;
  cursor: pointer;
  border: 1px solid #2e2e2e;

  &[data-ison='true'] {
    justify-content: flex-end;

    ${StyledSwitchHandle} {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 65px;
    height: 32px;
    padding: 3px 4px;
  }
`

// new row - 8
const StyledCatalogue = styled.div`
  padding: 64px 0 0;
  scroll-margin-top: 100px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 104px 0 0;
  }
`

const StyledCatalogueImage = styled.div`
  position: absolute;
  top: -45px;
  right: 10%;
  width: 52px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: -65px;
    right: 20%;
    width: 132px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: -50px;
    right: 10%;
  }
`
const StyledCatalogueControl = styled.div`
  padding-top: 32px;
  padding-bottom: 2px;
  ${({ theme }) => theme.mixins.flexbox()}
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    /* margin-top: 64px; */
    padding-top: 64px;
  }
`

// const StyledCatalogueControlBtn = styled.div`
//   &:not(:last-child) {
//     margin-right: 10px;
//   }
//   ${({ theme }) => theme.mixins.flexShrink(0)}
//   @media ${({ theme }) => theme.devices.mobileLgUp} {
//     &:not(:last-child) {
//       margin-right: 14px;
//     }
//   }
// `

const StyledCatalogueControlBtn = styled.a<{ clicked: boolean }>`
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  padding: 10px 18px;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.black};
  background: #fff;
  border-radius: 50px;
  flex: 1 0 auto;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}


  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
  ${({ clicked }) => {
    if (clicked) {
      return css`
        background-color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        color: #fff;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`

const StyledCatalogueModuleCardWrapper = styled.div`
  margin-top: 48px;
  padding-bottom: 40px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 18px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledCatalogueSwiper = styled(Swiper)`
  margin-top: 32px;
  display: none;
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
    margin-left: -40px !important;
    padding: 28px 40px 48px 40px !important;
  }
`

const StyledButton = styled(StyledLink)`
  margin: 18px auto 0;
  padding: 10px 28px;
  width: fit-content;

  &:hover {
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledIcon = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
`
// new row - 9

// new row - 10

const StyledLicenceBlock = styled.div`
  margin: 64px 0;
  padding: 24px 14px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.justifyContent('space-between')}
    padding: 32px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 30px 127.5px 28px 68px;
  }
`
const StyledLicenceBlockText = styled.div`
  color: #fff;

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    ${({ theme }) => theme.mixins.flexDirection('column')}
    ${({ theme }) => theme.mixins.justifyContent('center')}
  }
`

const StyledLicenceCaption = styled.p`
  background-color: ${({ theme }) => theme.colors.green10};
  color: ${({ theme }) => theme.colors.black};
  max-width: 120px;
  width: 100%;
  ${({ theme }) => theme.mixins.textSmall3}
  padding: 2.5px 12px 3.5px;
  border-radius: 10px;
  margin-bottom: 8px;
  text-align: center;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 175px;
    ${({ theme }) => theme.mixins.textSmall}
    padding: 9px 31px 9px;
    border-radius: 16px;
    margin-bottom: 18px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 30px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 0px;
    max-width: 189px;
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledLicenceTitleTop = styled.div`
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-bottom: 2px;
  }
`
const StyledLicenceTitleBottom = styled.div`
  margin-bottom: 16px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-bottom: 24px;
  }
`

const StyledLicenceParagraph = styled.p`
  ${({ theme }) => theme.mixins.textSmall}
  margin-bottom: 30px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H6}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 507px;
  }
`

const StyledLicenceBlockBgDecor = styled.div`
  position: absolute;
  bottom: -41px;
  right: -27px;
  max-width: 316px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 559px;
    bottom: -76px;
    right: -53px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 342px;
    bottom: -38px;
    right: -31px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 559px;
    bottom: -74px;
    right: -53px;
  }
`

const StyledLicenceBlockGif = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  position: relative;
  z-index: 2;
  margin: 0 auto;

  @media (max-width: 640px) {
    display: none;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 330px;
    min-width: 310px;
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 390px;
    min-width: 580px;
    width: 100%;
    ${({ theme }) => theme.mixins.justifyContent('end')}
  }
`

const StyledLicenceBlockImg = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  position: relative;
  z-index: 2;
  margin: 0 auto;

  @media (min-width: 641px) {
    display: none;
  }
`
// new row - 11
const StyledReviewsContainer = styled.section`
  margin: 64px 0;
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.noteUp} {
    /* margin: 160px 0; */
    padding-top: 168px;
  }
`
const StyledPulse = styled.div`
  position: absolute;
  bottom: -160%;
  right: 5%;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.textButton2}
  border-radius: 50%;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 1);
  width: 80px;
  height: 80px;
  animation: ${({ theme }) => theme.keyframes.pulse} 2s infinite;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    right: 10%;
    bottom: -30%;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    width: 94.59px;
    height: 94.59px;
    ${({ theme }) => theme.mixins.H4}
    right: 15%;
    bottom: -20%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 150px;
    height: 150px;
    ${({ theme }) => theme.mixins.H3}
    right: 0;
    bottom: 0;
  }
`

const StyledPulseAccent = styled(StyledAccent)`
  ${({ theme }) => theme.mixins.textSmall4}
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textSmall2}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.textButton}
  }
`
const StyledMiniTitle = styled.p`
  ${({ theme }) => theme.mixins.H6}
  margin-bottom: 14px;
`

const StyledReviewBackground = styled.div`
  position: absolute;
  top: 100px;
  right: calc(50% - 241px);
  width: 470px;

  @media ${({ theme }) => theme.devices.mobileSmUp} {
    top: 72px;
    right: calc(50% - 324px);
    width: 530px;
  }

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    top: 0px;
    right: calc(50% - 364px);
    width: 530px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: 41px;
    right: calc(50% - 421px);
    width: 537px;
  }

  @media ${({ theme }) => theme.devices.ipadUp} {
    top: 0px;
    right: calc(50% - 455px);
    width: 537px;
  }

  @media ${({ theme }) => theme.devices.ipadBigUp} {
    top: 5px;
    right: calc(50% - 513px);
    width: 650px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: 30px;
    right: calc(50% - 570px);
    width: 800px;
  }

  @media ${({ theme }) => theme.devices.desktopUp} {
    top: 0px;
    right: calc(50% - 790px);
    width: 1020px;
  }
`

const StyledReviewsSwiper = styled(Swiper)`
  margin-top: 186px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-top: 56px;
    margin-left: -30px !important;
    padding: 30px !important;
  }
`
const StyledReviewItem = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 27px 18px;

  box-shadow: -3px 2px 23px rgba(0, 0, 0, 0.05);

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding: 18px 15px;
  }
`
const StyledReviewItemHeader = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
  }
`

const StyledReviewItemHeaderProfile = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  gap: 18px;
`

const StyledReviewItemHeaderProfileImage = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexShrink(0)}
  width: 51px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 45px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 79px;
  }
`

const StyledReviewItemHeaderProfileInformation = styled.div``

const StyledReviewItemName = styled.h4`
  font-weight: 600;
  font-size: 17.119px;
  line-height: 21px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.textButton}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H4}
  }
`

const StyledReviewItemCourse = styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 13.6952px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.grey};

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledReviewItemCourseAccent = styled.span`
  font-weight: 400;
  font-size: 13.6952px;
  line-height: 17px;
  color: #000;
  text-decoration-line: underline;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.textLink}
  }
`

const StyledReviewItemHeaderStar = styled.span`
  margin-top: 18px;
  ${({ theme }) => theme.mixins.flexbox()}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-top: 13px;
    margin-left: auto;
  }
`
const StyledReviewItemHeaderStarWrapper = styled.div``

const StyledReviewItemContent = styled.p`
  white-space: pre-wrap;
  margin-top: 18px;
  margin-bottom: 24px;
  ${({ theme }) => theme.mixins.textSmall}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.textNormal}
    margin-top: 24px;
  }
`

const StyledReviewFrom = styled.a`
  ${({ theme }) => theme.mixins.textSmall3}
  border: 1px solid #191919;
  border-radius: 5px;
  padding: 4px 10px;
  margin-top: 24px;
  max-width: 72px;
  text-align: center;
  color: #191919;
  text-decoration: none;
  cursor: pointer;
`

const StyledReviewControl = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.justifyContent('start')}
  }
`

const StyledReviewBtnReserve = styled(ButtonDefault)`
  ${({ theme }) => theme.mixins.rotate('180deg')}
`

const StyledReviewBtnIcon = styled(props => <ROUNDEDBTN {...props} />)`
  width: 24px;
  height: 25px;
  stroke: #191919;
`

const StyledBulletContainer = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  margin: 0 15px;
`

const StyledBullet = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : '#191919'};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  border-radius: 50px;
  margin: 0 4px;
`
// new row - 12
const StyledCertificateWrapper = styled.section`
  background: #f3f4f6;
  padding: 42px 0;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 85px 0;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 85px 0 0;
  }
`

const StyledCerificateHeadingBlock = styled.div`
  position: relative;
  padding: 18px 51px 18px 18px;
  border-radius: 20px;
  background-color: #fff;
  margin-bottom: 56px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 28px 17px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-bottom: 120px;
    padding: 42px 50px;
  }
`

const StyledCertificateTitle = styled.h2`
  ${({ theme }) => theme.mixins.H4}
  margin-bottom: 12px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H3}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
  }
`

const StyledCertificateTitleText = styled.p`
  ${({ theme }) => theme.mixins.textSmall}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H6}
  }
`

const StyledCertificateTitleDecor = styled.div`
  max-width: 57px;
  width: 100%;
  position: absolute;
  right: 4px;
  bottom: -26px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 100px;
    right: 28px;
    bottom: -52px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 153.66px;
    right: 66.34px;
    bottom: -52px;
  }
`

const StyledCertificateContentBlock = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.justifyContent('space-between')}
  }
`

const StyledCertificateContentLeft = styled.div`
  margin-bottom: 112px;
  position: relative;
  z-index: 2;

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-bottom: 0px;
  }
`

const StyledCertificateLeftHeading = styled.h3`
  ${({ theme }) => theme.mixins.H5}
  margin-bottom: 8px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H4}
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 535.14px;
    margin: 0 auto 6px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
    margin-bottom: 6px;
  }
`

const StyledCertificateLeftPrice = styled.p`
  ${({ theme }) => theme.mixins.textNormal}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H5}
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 535.14px;
    margin: 0 auto;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-bottom: 2px;
  }
`

const StyledCertificateLeftImgMan = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}

  @media ${({ theme }) => theme.devices.mobileSmUp} {
    margin: 0 auto;
    max-width: 535.14px;
  }
`

const StyledCertificateContentRight = styled.div`
  position: relative;
  z-index: 2;
`

const StyledCertificateRightHeading = styled.h3`
  ${({ theme }) => theme.mixins.H5}
  margin-bottom: 8px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H4}
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 535.14px;
    margin: 0 auto 6px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

const StyledCerificateRightCaption = styled.p`
  ${({ theme }) => theme.mixins.textNormal}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H5}
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 535.14px;
    margin: 0 auto;
  }
`

const StyledCertificateRightSpan = styled.span`
  ${({ theme }) => theme.mixins.H5}
  color: ${({ theme }) => theme.colors.primary};

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H4}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

const StyledCertificateRightDiplom = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}

  @media ${({ theme }) => theme.devices.mobileSmUp} {
    margin: 0 auto;
    max-width: 473.3px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    position: relative;
    top: -80px;
  }
`

const StyledCertificateSvgLineDashed = styled.svg`
  position: absolute;
  z-index: 1;
  top: 143px;
  right: calc(50% - 188px);
  max-width: 856px;
  width: 100%;
  max-height: 345px;
  height: 100%;
  transform: rotate(90deg);

  @media (min-width: 336px) {
    top: 154px;
    right: calc(50% - 216px);
  }

  @media (min-width: 366px) {
    top: 185px;
    right: calc(50% - 221px);
  }

  @media (min-width: 425px) {
    top: 237px;
    right: calc(50% - 259px);
  }

  @media ${({ theme }) => theme.devices.mobileSmUp} {
    top: 287px;
    right: -54px;
  }

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    top: 350px;
    right: calc(50% - 337px);
  }

  @media (min-width: 670px) {
    top: 410px;
    right: calc(50% - 372px);
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: 359px;
    right: calc(50% - 473px);
  }

  @media (min-width: 800px) {
    top: 316px;
    right: calc(50% - 470px);
  }

  @media (min-width: 889px) {
    top: 294px;
    right: calc(50% - 523px);
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: 68px;
    right: calc(50% - 531px);
    max-height: 270px;
    transform: rotate(0deg);
  }
`

// new row - 13
const StyledBlog = styled.section`
  @media ${({ theme }) => theme.devices.mobileMdUp} {
  }
`
const StyledBlogContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding: 0 0 0 15px;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 1284px;
    padding: 0 0 0 30px;
  }
`
const StyledBlogHeader = styled.div`
  padding-top: 64px;
  padding-bottom: 56px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding-bottom: calc(225px + 53px);
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding-bottom: calc(356px + 56px);
  }
`

const StyledBlogTitle = styled(StyledTitle)`
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H1}
  }
`

const StyledBlogTitleAccent = styled(StyledAccent)`
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
  }
`

const StyledBlogDescription = styled.p`
  ${({ theme }) => theme.mixins.textNormal}
  max-width: 260px;
  margin-top: 12px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 379px;
    margin-top: 24px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 527px;
    ${({ theme }) => theme.mixins.H6}
  }
`

const StyledBlogButton = styled(StyledLink)`
  margin-top: 32px;
  padding: 10px 28px;
  width: fit-content;
  z-index: 1;
  &:hover {
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledBlogArticles = styled.div`
  height: 347px;
  position: relative;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileXsUp} {
    height: 400px;
  }

  @media ${({ theme }) => theme.devices.mobileSmUp} {
    height: 500px;
  }

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: none;
  }
`

const StyledBlogArticles1 = styled.div<{ isViewPort: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ isViewPort }) =>
    isViewPort &&
    css`
      animation: ${({ theme }) =>
          theme.keyframes.moveVerticallyFadeIn('-50%, 200px', '-50%, -50%')}
        3s;
    `}
`

const StyledBlogArticlesDesktop1 = styled.div<{ isViewPort: boolean }>`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    width: 220px;
    position: absolute;
    top: 56%;
    right: 10%;
    transform: translate(-10%, -56%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-10%, 400px', '-10%, -56%')}
          3s;
      `}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 350px;
    right: 20%;
    transform: translate(-20%, -56%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-20%, 400px', '-20%, -56%')}
          3s;
      `}
  }
`

const StyledBlogArticles2 = styled.div<{ isViewPort: boolean }>`
  position: absolute;
  z-index: -3;
  right: 20%;
  bottom: 0;
  transform: translate(-20%, 0);

  ${({ isViewPort }) =>
    isViewPort &&
    css`
      animation: ${({ theme }) =>
          theme.keyframes.moveVerticallyFadeIn('-20%, 200px', '-20%, 0')}
        3s;
    `}
`

const StyledBlogArticlesDesktop2 = styled.div<{ isViewPort: boolean }>`
  display: none;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    position: absolute;
    z-index: -3;
    right: 5%;
    bottom: 3%;
    transform: translate(-5%, -3%);
    width: 100px;

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-5%, 400px', '-5%, -3%')}
          3s;
      `}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 160px;
    right: 17%;
    transform: translate(-17%, -3%);
    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-17%, 400px', '-17%, -3%')}
          3s;
      `}
  }
`

const StyledBlogArticles3 = styled.div<{ isViewPort: boolean }>`
  position: absolute;
  top: 25%;
  right: -10%;
  transform: translate(10%, -25%);

  ${({ isViewPort }) =>
    isViewPort &&
    css`
      animation: ${({ theme }) =>
          theme.keyframes.moveVerticallyFadeIn('10%, 200px', '10%, -25%')}
        3s;
    `}
`

const StyledBlogArticlesDesktop3 = styled.div<{ isViewPort: boolean }>`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    position: absolute;
    width: 146px;
    top: 35%;
    right: -8%;
    transform: translate(8%, -35%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('8%, 400px', '8%, -35%')}
          3s;
      `}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    right: 10%;
    width: 190px;
    transform: translate(-10%, -35%);
    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-10%, 400px', '-10%, -35%')}
          3s;
      `}
  }
`

const StyledBlogArticlesDesktop4 = styled.div<{ isViewPort: boolean }>`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    width: 126px;
    position: absolute;
    bottom: 15%;
    left: 40%;
    transform: translate(-40%, -15%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-40%, 400px', '-40%, -15%')}
          3s;
      `}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 200px;

    left: 30%;
    transform: translate(-30%, -15%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-30%, 400px', '-30%, -15%')}
          3s;
      `}
  }
`

const StyledBlogArticles5 = styled.div<{ isViewPort: boolean }>`
  position: absolute;
  bottom: 20%;
  left: -10%;
  transform: translate(10%, -20%);

  ${({ isViewPort }) =>
    isViewPort &&
    css`
      animation: ${({ theme }) =>
          theme.keyframes.moveVerticallyFadeIn('10%, 200px', '10%, -20%')}
        3s;
    `}
`

const StyledBlogArticlesDesktop5 = styled.div<{ isViewPort: boolean }>`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    width: 126px;
    position: absolute;
    bottom: 3%;
    left: 5%;
    transform: translate(-5%, -3%);

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('-5%, 400px', '-5%, -3%')}
          3s;
      `}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 200px;
  }
`

const StyledBlogLogo = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;
  height: 44px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-top: 140px;
    margin-bottom: 96px;
    height: 83px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 100px;
    margin-bottom: 90px;
    height: 150px;
  }
`

const StyledBlogLogoLetter = styled.div<{ isViewPort: boolean }>`
  &:nth-child(odd) {
    align-self: end;

    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('0, 100px', '0, 0')}
          3s;
      `}
  }
  &:nth-child(even) {
    ${({ isViewPort }) =>
      isViewPort &&
      css`
        animation: ${({ theme }) =>
            theme.keyframes.moveVerticallyFadeIn('0, 100px', '0, 0')}
          3.5s;
      `}
  }
`

const StyledBlogLogoLetterW = styled(StyledBlogLogoLetter)`
  width: calc(35px - 20%);

  @media ${({ theme }) => theme.devices.mobileXxs} {
    width: 35px;
  }
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 66.4px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 140px;
  }
`

const StyledBlogLogoLetterI = styled(StyledBlogLogoLetter)`
  width: calc(6px - 10%);

  @media ${({ theme }) => theme.devices.mobileXxs} {
    width: 6px;
  }

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 10.91px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 23px;
  }
`

const StyledBlogLogoLetterL = styled(StyledBlogLogoLetter)`
  width: calc(17px - 20%);

  @media ${({ theme }) => theme.devices.mobileXxs} {
    width: 17px;
  }

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 32.73px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 69px;
  }
`

const StyledBlogLogoLetterS = styled(StyledBlogLogoLetter)`
  width: calc(19px - 20%);
  @media ${({ theme }) => theme.devices.mobileXxs} {
    width: 19px;
  }
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 36.05px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 76px;
  }
`

const StyledBlogLogoLetterK = styled(StyledBlogLogoLetter)`
  width: calc(21px - 20%);

  @media ${({ theme }) => theme.devices.mobileXxs} {
    width: 21px;
  }
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 40.31px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 85px;
  }
`

const StyledBlogLogoContainer = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 20px;
  justify-items: center;
  height: 100%;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding: 0 15px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 1284px;
    padding: 0 30px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1399px;
    padding: 0;
  }
`
// new row - 14

const StyledLearnFreeWrapper = styled.section`
  background: #f3f4f6;
  padding: 24px 0;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 40px 0;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 64px 0;
  }
`

const StyledLearnFreeTitle = styled(StyledTitle)`
  margin: 0 auto;
  width: fit-content;
  text-align: center;

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H1};
  }
`

const StyledLearnFreeTitleSpan = styled.span`
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLearnFreeTitleSpan2 = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const StyledLearnFreeCaption = styled(StyledDescription)`
  ${({ theme }) => theme.mixins.textButton2};
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 32px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    margin-bottom: 40px;
    ${({ theme }) => theme.mixins.H4};
  }
`

const StyledLearnFreeContent = styled.div`
  border-radius: 20px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  ${({ theme }) => theme.mixins.alignItems('flex-start')}
  position: relative;
  overflow: hidden;
  padding: 24px 16px;
  min-height: 451px;

  @media (min-width: 350px) {
    min-height: 480px;
  }

  @media (min-width: 385px) {
    min-height: 495px;
  }

  @media (min-width: 420px) {
    min-height: 510px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 17px;
    min-height: 287px;
    ${({ theme }) => theme.mixins.alignItems('center')}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    min-height: 395px;
    padding: 54px;
  }
`

const StyledLearnFreeContentBg = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  min-height: 451px;
  overflow: hidden;
  ${({ theme }) => theme.mixins.flexbox()}

  @media (min-width: 350px) {
    min-height: 480px;
  }

  @media (min-width: 385px) {
    min-height: 495px;
  }

  @media (min-width: 420px) {
    min-height: 510px;
  }
`

const StyledLearnFreeTextBlock = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  width: 100%;
  position: relative;
  z-index: 2;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 286px;
  }

  @media ${({ theme }) => theme.devices.ipadBigUp} {
    max-width: 510px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 570px;
  }
`

const StyledLearnFreeTextBlockHeading = styled.h3`
  color: #fff;
  ${({ theme }) => theme.mixins.H4};
  margin-bottom: 24px;
  max-width: 185px;
  position: relative;
  z-index: 5;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 570px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2};
    margin-bottom: 40px;
    max-width: 570px;
  }
`

const StyledLearnFreeTextBlockHeadingSpan = styled.span`
  color: ${({ theme }) => theme.colors.orange5};
`

const StyledLearnFreeTextBlockBtn = styled(StyledLink)`
  padding: 10px 28px;
  width: fit-content;

  &:hover {
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledLearnFreeGalleryCircle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(90deg);
  max-width: 242px;
  max-height: 242px;
  right: -31px;
  bottom: -74px;

  @media (min-width: 350px) {
    right: -34px;
    bottom: -88px;
  }

  @media (min-width: 420px) {
    right: -5px;
    bottom: -100px;
  }

  @media (min-width: 590px) {
    right: calc(50% - 293px);
    bottom: -100px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 286px;
    max-height: 286px;
    right: 18px;
    bottom: -135px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    right: -37px;
    bottom: -240px;
    max-width: 486px;
    max-height: 486px;
  }

  @media ${({ theme }) => theme.devices.desktopUp} {
    max-width: 486px;
    max-height: 486px;
    right: -37px;
    bottom: -240px;
  }
`
const StyledLearnFreeImg = styled.div`
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 150%;
  -webkit-transform-origin: 50% 150%;
  position: relative;
  max-width: 425px;
  top: 24px;

  @media (min-width: 420px) {
    top: 20px;
    left: calc(50% - 205px);
    max-width: 425px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: 10px;
    left: 285px;
    max-width: 373px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: 20px;
    left: 310px;
    max-width: 526px;
  }
`

const StyledLearnFreeGallery = styled.div`
  position: absolute;
  top: 175px;
  right: 0px;
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 330px;
  max-width: 700px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 687px;
    max-height: 287px;
    top: 0px;
    right: 0px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 900px;
    max-height: 395px;
    top: 0px;
    right: 0px;
  }

  &::after {
    content: '';
    position: absolute;
  }

  > div {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    transform-origin: 70% 160%;
    -webkit-transform-origin: 70% 160%;
    @media ${({ theme }) => theme.devices.mobileLgUp} {
      transform-origin: 65% 160%;
      -webkit-transform-origin: 65% 160%;
    }
  }

  &::after,
  > div {
    animation: ${({ theme }) => theme.keyframes.circleImgSlider} 24s infinite
      cubic-bezier(0.5, -0.2, 0.5, 1.2);
    -webkit-animation: ${({ theme }) => theme.keyframes.circleImgSlider} 24s
      infinite cubic-bezier(0.5, -0.2, 0.5, 1.2);
  }

  > div:nth-child(2) {
    animation-delay: -6s;
  }
  > div:nth-child(3) {
    animation-delay: -12s;
  }
  > div:nth-child(4) {
    animation-delay: -18s;
  }
`

const StyledLearnFreeImgLink = styled.div`
  color: #fff;
  position: absolute;
  z-index: 3;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  width: 124px;
  bottom: 116px;
  right: 16px;
  cursor: pointer;

  @media (min-width: 350px) {
    bottom: 97px;
    right: 16px;
  }

  @media (min-width: 385px) {
    bottom: 69px;
    right: 16px;
  }

  @media (min-width: 420px) {
    bottom: 57px;
    right: 20px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    bottom: 38px;
    right: 0px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    bottom: 60px;
    right: 0px;
  }
`

const StyledLearnFreeImgLinkArrow = styled.a`
  position: absolute;
  z-index: 3;
  cursor: pointer;
  bottom: 97px;
  right: 16px;

  @media (min-width: 350px) {
    bottom: 78px;
    right: 16px;
  }

  @media (min-width: 385px) {
    bottom: 50px;
    right: 16px;
  }

  @media (min-width: 420px) {
    bottom: 42px;
    right: 20px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    bottom: 19px;
    right: 0px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    bottom: 41px;
    right: 0px;
  }
`

// new row - 15 comments
const StyledCommentsContainer = styled.section``

const StyledComments = styled.div`
  padding: 68px 0;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 128px 0;
  }
`

// new row - 16 Cooperate with leading companies
// const StyledCooperateContainer = styled.section``
//
// const StyledTitleNextLine = styled.div``
//
// const StyledCooperate = styled.div`
//   padding-bottom: 64px;
//   @media ${({ theme }) => theme.devices.mobileLgUp} {
//     padding-bottom: 88px;
//   }
//
//   @media ${({ theme }) => theme.devices.noteUp} {
//     padding-bottom: 160px;
//   }
// `
//
// const StyledCooperateTitle = styled(StyledTitle)`
//   padding-bottom: 24px;
//   border-bottom: 2px solid #ececec;
// `
//
// const StyledCooperateIcons = styled.div`
//   margin-top: 32px;
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-row-gap: 44px;
//   grid-column-gap: 37px;
//
//   @media ${({ theme }) => theme.devices.mobileLgUp} {
//     margin-top: 40px;
//     grid-template-columns: repeat(4, 1fr);
//     grid-row-gap: 48px;
//     grid-column-gap: 36px;
//   }
//   @media ${({ theme }) => theme.devices.noteUp} {
//     margin-top: 48px;
//     grid-row-gap: 109px;
//     grid-column-gap: 103px;
//   }
// `
//
// const StyledCooperateIcon = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//
//   @media ${({ theme }) => theme.devices.mobileLgUp} {
//     justify-content: start;
//   }
// `

// new row - 17
const StyledCheckCert = styled.section`
  position: relative;
`

const StyledCheckCertMobile = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledCheckCertDesktop = styled.div`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
  }
`
const StyledCheckCertContainer = styled.div`
  padding: 112.83px 0 96.33px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding: 160px 0 143px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 148px 0 156px;
  }
`

const StyledCheckGirl = styled.div`
  position: absolute;
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
    width: 263px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 381px;
  }
`

const StyledCheckTodo = styled.div`
  position: absolute;
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    display: block;
    top: 80%;
    right: 10%;
    transform: translate(-10%, -80%);
    width: 191px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 324px;
  }
`

const StyledCheckStart1 = styled.div`
  width: 12.5px;
  position: absolute;
  top: -60px;
  right: -20px;
`

const StyledCheckStart2 = styled.div`
  width: 25px;
  position: absolute;
  top: -50px;
  right: -60px;
`

const StyledCheckStart3 = styled.div`
  width: 15px;
  position: absolute;
  top: -20px;
  right: -68px;
`

const StyledCheckStart4 = styled.div`
  width: 18px;
  position: absolute;
  bottom: -10px;
  left: -50px;
`

const StyledCheckStart5 = styled.div`
  width: 15px;
  position: absolute;
  bottom: -50px;
  left: -24px;
`

const StyledCheckCertWrapper = styled.div`
  position: relative;
  width: 180px / 2;
  height: 180px;
  border-radius: 50%;
  margin: 0 auto;

  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 280px / 2;
    height: 276px;
  }
`
const StyledCheckCertCircleText = styled.div`
  ${({ theme }) => theme.mixins.textButton}
  font-weight: 800;
  position: absolute;
  width: 100%;
  max-width: 180px;
  height: 100%;
  top: 0;
  animation: ${({ theme }) => theme.keyframes.rotate} 20s linear infinite;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H4}
    max-width: 280px;
  }
`

const StyledCheckCertCircleTextLetter = styled.span<{ index: number }>`
  position: absolute;
  left: 50%;
  transform-origin: 0 90px;
  ${({ index }) => {
    const deg = index * 10.2
    return css`
      transform: rotate(${deg}deg);
    `
  }}
  @media ${({ theme }) => theme.devices.noteUp} {
    transform-origin: 0 140px;
  }
`

const StyledCheckCertCircleContainer = styled.div`
  width: 119.83px;
  height: 119.83px;
  position: relative;

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 200px;
    height: 194px;
  }
`

const StyledCheckCertCircle = styled.a`
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  z-index: 3;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  &:hover {
    & + div {
      animation: ${({ theme }) => theme.keyframes.fadeOut} 1s;
      animation-fill-mode: forwards;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

const StyledCheckCertCircleShadow = styled.div`
  position: absolute;
  top: 2px;
  right: -3px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  width: 100%;
  height: 100%;
  border-radius: 50%;
`
// new row - 18

const StyledFormBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  padding: 41px 16px 304px 16px;
  border-radius: 20px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  position: relative;
  overflow: hidden;
  margin: 64px 0;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 32px 17px 44px;
    margin: 79px 0;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 74px 56px;
    margin: 160px 0;
  }
`

const StyledFormBlockDecorSmall = styled.div`
  position: absolute;
  top: -52px;
  left: -69px;
  max-width: 218px;
  max-height: 218px;

  @media ${({ theme }) => theme.devices.noteUp} {
    top: -95px;
    left: -100px;
    max-width: 249px;
    max-height: 249px;
  }
`

const StyledFormBlockDecorBig = styled.div`
  position: absolute;
  bottom: -50px;
  right: -50px;
  max-width: 302px;
  max-height: 302px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 331px;
    max-height: 331px;
    bottom: -130px;
    right: -80px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 537px;
    max-height: 537px;
    bottom: -180px;
    right: -125px;
  }
`

const StyledFormBlockTitle = styled(StyledTitle)`
  color: #fff;
  margin-bottom: 16px;
  position: relative;
  z-index: 3;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H3}
    margin-bottom: 14px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 700px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
    max-width: 605px;
    margin-bottom: 16px;
  }
`

const StyledFormBlockCaption = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #fff;
  position: relative;
  z-index: 3;
  margin-bottom: 64px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    line-height: 20px;
    max-width: 390px;
    margin-bottom: 46px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 560px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 56px;
  }
`

const StyledFormBlockWrapper = styled.form`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  position: relative;
  z-index: 3;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 332px;
  }

  @media ${({ theme }) => theme.devices.ipadBigUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.justifyContent('space-between')}
    ${({ theme }) => theme.mixins.alignItems('center')}
    max-width: 580px;
    margin-bottom: 18px;
  }
`

const StyledFormBlockInput = styled.input`
  border-radius: 40px;
  padding: 13px 28px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textNormal}
  margin-bottom: 9px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 332px;
  }

  @media ${({ theme }) => theme.devices.ipadBigUp} {
    max-width: 332px;
    width: 100%;
    margin-bottom: 0px;
  }
`

const StyledFormBlockBtn = styled.button`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 50px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 14px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 254px;
  }

  @media ${({ theme }) => theme.devices.ipadBigUp} {
    max-width: 217px;
    width: 100%;
    margin-bottom: 0px;
  }
`

const StyledFormBlockBtnIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  cursor: pointer;
`

const StyledFormBlockAgreementCaption = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black6};

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    max-width: 330px;
  }
`

const StyledFormBlockAgreementLink = styled.a`
  color: ${({ theme }) => theme.colors.black6};
`

const StyledFormBlockPhoneGif = styled.div`
  position: absolute;
  z-index: 3;
  bottom: -4px;
  right: 0px;
  max-width: 266px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 303px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 407px;
  }
`

// new row - 19
const StyledBrandsContainer = styled.div`
  margin: 80px 0;
  text-align: center;
  @media ${({ theme }) => theme.devices.noteUp} {
    margin: 40px 0;
  }
`

const StyledBrandsTitle = styled.h2`
  ${({ theme }) => theme.mixins.H4}
  text-align: center;
  margin-bottom: 24px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.H3}
    margin-bottom: 18px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H1}
    margin-bottom: 24px;
  }
`

const StyledBrandsLogosContainer = styled.div`
  height: 177px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('row')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    height: 202px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    height: 248px;
  }
`

const StyledBrandsLogosInnerContainer = styled.div`
  width: 65px;
  height: 65px;
  position: relative;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 90px;
    height: 90px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 120px;
    height: 120px;
  }
`

const StyledBrandsCircleBorder = styled.a<{ isSpread?: boolean | null }>`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: linear-gradient(to top, rgba(25, 25, 25, 0.25), #eef9f4);
  padding: 0.54px;
  cursor: pointer;
  z-index: 10;
  position: relative;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 90px;
    height: 90px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 120px;
    height: 120px;
  }
`
const StyledBrandsCircleInvisible = styled.div`
  width: 65px;
  height: 65px;
  background: #fff;
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 90px;
    height: 90px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 120px;
    height: 120px;
  }
`

const StyledBrandsCircle = styled.div`
  background: rgba(232, 246, 240, 0.2);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('row')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
`

const StyledBrandsCircleInnder = styled.div`
  width: 36.83px;
  height: 36.83px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('row')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}

  path {
    stroke: #fff;
  }
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 51px;
    height: 51px;

    svg {
      width: 27px;
      height: 27px;
    }
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 68px;
    height: 68px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
`
const StyledBrandsCircleBorderAbstract = styled(StyledBrandsCircleBorder)`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('row')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  position: absolute;
  z-index: 8;
`

const StyledBrandsCircleFirst = styled(StyledBrandsCircleBorderAbstract)`
  display: none;

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 48px;
    height: 48px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-100px - 26px - 80px - 36px - 62px - 35px - 48px - 33px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-100px - 26px - 80px - 36px - 62px - 35px - 48px - 33px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleSecond = styled(StyledBrandsCircleBorderAbstract)`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    /* 90 - 48.3% */
    width: 46.5px;
    height: 46.5px;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-75px - 16.6px - 60px - 23px - 46.5px - 31px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-75px - 16.6px - 60px - 23px - 46.5 - 31px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 62px;
    height: 62px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-100px - 26px - 80px - 36px - 62px - 35px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(100px - 26px - 80px - 36px - 62px - 35px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleSecondImage = styled.div`
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: calc(29.59px - 15%);
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: calc(29.59px);
  }
`

const StyledBrandsCircleThird = styled(StyledBrandsCircleBorderAbstract)`
  /* -33.3 уменьшается */
  width: 43.3px;
  height: 43.3px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  /* промежуток + 38.4% увеличится */
  /* right: calc(-54.21px + -12px + -43.3px + -16.6px);
  transform: translateY(-50%); */

  ${({ isSpread }) => {
    if (isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              '0, -50%',
              'calc(-54.21px + -12px + -43px + -16.6px), -50%',
            )},
          ${({ theme }) => theme.keyframes.appear};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
    if (isSpread !== null && !isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              'calc(-54.21px - 12px - 43px - 16.6px), -50%',
              '0, -50%',
            )},
          ${({ theme }) => theme.keyframes.fadeOut};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 60px;
    height: 60px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-75px - 16.6px - 60px - 23px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-75px - 16.6px - 60px - 23px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 80px;
    height: 80px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-100px - 26px - 80px - 36px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-100px - 26px - 80px - 36px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleThirdImage = styled.div`
  width: 18.57px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 24.7px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 26px;
  }
`

const StyledBrandsCircleBorderFourth = styled(StyledBrandsCircleBorderAbstract)`
  width: 54.21px;
  height: 54.21px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  ${({ isSpread }) => {
    if (isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              '0, -50%',
              'calc(-54.21px + -12px), -50%',
            )},
          ${({ theme }) => theme.keyframes.appear};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
    if (isSpread !== null && !isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              'calc(-54.21px + -12px), -50%',
              '0, -50%',
            )},
          ${({ theme }) => theme.keyframes.fadeOut};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 75px;
    height: 75px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-75px - 16.6px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-75px - 16.6px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 100px;
    height: 100px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(-100px - 26px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(-100px - 26px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleBorderFourthImage = styled.div`
  width: 30px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 39.9px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 43.05px;
  }
`

const StyledBrandsCircleBorderSixth = styled(StyledBrandsCircleBorderAbstract)`
  /* -16.6% уменьшается */
  width: 54.21px;
  height: 54.21px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  ${({ isSpread }) => {
    if (isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate('0, -50%', 'calc(54.21px + 12px), -50%')},
          ${({ theme }) => theme.keyframes.appear};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
    if (isSpread !== null && !isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate('calc(54.21px + 12px), -50%', '0, -50%')},
          ${({ theme }) => theme.keyframes.fadeOut};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 75px;
    height: 75px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(75px + 16.6px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(75px + 16.6px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 100px;
    height: 100px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate('0, -50%', 'calc(100px + 26px), -50%')},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate('calc(100px + 26px), -50%', '0, -50%')},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleBorderSixthImage = styled.div`
  width: 30px;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 39.9px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 53px;
  }
`

const StyledBrandsCircleSeventh = styled(StyledBrandsCircleBorderAbstract)`
  /* -33.3 уменьшается */
  width: 43.3px;
  height: 43.3px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  /* промежуток + 38.4% увеличится */
  /* right: calc(-54.21px + -12px + -43.3px + -16.6px);
  transform: translateY(-50%); */

  ${({ isSpread }) => {
    if (isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              '0, -50%',
              'calc(54.21px + 12px + 43px + 16.6px), -50%',
            )},
          ${({ theme }) => theme.keyframes.appear};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
    if (isSpread !== null && !isSpread) {
      return css`
        animation-name: ${({ theme }) =>
            theme.keyframes.translate(
              'calc(54.21px + 12px + 43px + 16.6px), -50%',
              '0, -50%',
            )},
          ${({ theme }) => theme.keyframes.fadeOut};
        animation-duration: 1s;
        animation-fill-mode: forwards;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 60px;
    height: 60px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(75px + 16.6px + 60px + 23px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(75px + 16.6px + 60px + 23px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 80px;
    height: 80px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(100px + 26px + 80px + 36px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(100px + 26px + 80px + 36px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleSeventhImage = styled.div`
  width: 18.57px;

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: 24.7px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 32.59px;
  }
`

const StyledBrandsCircleEight = styled(StyledBrandsCircleBorderAbstract)`
  display: none;
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    /* 90 - 48.3% */
    width: 46.5px;
    height: 46.5px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(75px + 16.6px + 60px + 23px + 46.5px + 31px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(75px + 16.6px + 60px + 23px + 46.5 + 31px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 62px;
    height: 62px;

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(100px + 26px + 80px + 36px + 62px + 35px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(100px + 26px + 80px + 36px + 62px + 35px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBrandsCircleEightImage = styled.div`
  @media ${({ theme }) => theme.devices.mobileMdUp} {
    width: calc(32.82px - 15%);
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: calc(32.82px);
  }
`

const StyledBrandsCircleNineth = styled(StyledBrandsCircleBorderAbstract)`
  display: none;

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    width: 48px;
    height: 48px;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    ${({ isSpread }) => {
      if (isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                '0, -50%',
                'calc(100px + 26px + 80px + 36px + 62px + 35px + 48px + 33px), -50%',
              )},
            ${({ theme }) => theme.keyframes.appear};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
      if (isSpread !== null && !isSpread) {
        return css`
          animation-name: ${({ theme }) =>
              theme.keyframes.translate(
                'calc(100px + 26px + 80px + 36px + 62px + 35px + 48px + 33px), -50%',
                '0, -50%',
              )},
            ${({ theme }) => theme.keyframes.fadeOut};
          animation-duration: 1s;
          animation-fill-mode: forwards;
        `
      }
    }}
  }
`

const StyledBransSelectButton = styled(StyledLink)`
  padding: 10px 28px;
  width: fit-content;
  margin: 0 auto;
  &:hover {
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

import styled from 'styled-components'

const StyledDefaultButton = styled.button`
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font: 1.4rem 'APE', sans-serif;
  ${({ theme }) => theme.mixins.transition('0.3s all')}
  text-align: center;
  /* line-height: 1.214; */
  ${({ theme }) => theme.mixins.flexShrink(0)}
  ${({ theme }) => theme.mixins.alignSelf('center')}
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
`

export default StyledDefaultButton
